import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ClassificationTypeDto, ClassificationTypesLexiClient, ClassificationValueDto, ClassificationValuesLexiClient, CompteGeneralDto, ComptesGenerauxLexiClient, CsvImportResultDto, ObjectType, StockageConnaissement, TarifRevatuaDto, TarifsRevatuaLexiClient } from '@lexi-clients/lexi';
import { DxFormComponent, DxTreeListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ClassificationTypeService } from 'lexi-angular/src/app/services/classification-type.service';
import { ClassificationValueService } from 'lexi-angular/src/app/services/classification-value.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { DownloadService } from 'lexi-angular/src/app/services/download.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { lastValueFrom } from 'rxjs';

const TypeIdDatafield = 'classificationTypeId';

@Component({
  selector: 'app-classification-values-list',
  templateUrl: './classification-values-list.component.html',
  styleUrls: ['./classification-values-list.component.scss'],
})
export class ClassificationValuesListComponent implements OnInit {
  typesDatasource: DataSource;
  classificationStore: CustomStore;
  parentsLookupData = [];
  currentClassificationTypeId: number = null;
  currentClassificationType: ClassificationTypeDto;
  isTypeArticle: boolean = false;
  showPopup = false;
  newClassificationValueRacine: ClassificationValueDto = {};
  comptesGeneraux: CompteGeneralDto[];
  tarifsRevatua: TarifRevatuaDto[];
  stockageRevatuaList = [
    { id: StockageConnaissement.cale, intitule: "Cale" },
    { id: StockageConnaissement.refrigere, intitule: "Réfrigérée" },
    { id: StockageConnaissement.congele, intitule: "Congelé" },
    { id: StockageConnaissement.pontee, intitule: "Pontée" },
  ];
  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
  @ViewChild('formNewClassificationRacine') formNewClassificationRacine: DxFormComponent;

  showImportCsvPopup = false;
  fichierCsv: File[] = [];
  importCsvResultMsg: string;
  importCsvResultMsg2: string;
  importHasFailed = false;
  showLoader = false;
  recuperationTemplateEncours = false;

  constructor(
    private readonly classificationTypesService: ClassificationTypeService,
    private readonly classificationService: ClassificationValueService,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly classificationTypesLexiClient: ClassificationTypesLexiClient,
    private readonly compteGenerauxLexiClient: ComptesGenerauxLexiClient,
    private readonly tarifsRevatuaLexiClient: TarifsRevatuaLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly downloadService: DownloadService
  ) { }

  async ngOnInit() {
    this.comptesGeneraux = await lastValueFrom(this.compteGenerauxLexiClient.getAll());
    this.tarifsRevatua = await lastValueFrom(this.tarifsRevatuaLexiClient.getAll());
    this.setDataSources();
  }

  typesDisplayExpr(data) {
    return data && `${data.codeBo} ⟷ ${data.intitule}`;
  }

  codeTarifRevatuaDisplayExpr(data: TarifRevatuaDto) {
    return data && `${data.code} - ${data.intitule}`;
  }

  compteGeneralDisplayExpr (data: CompteGeneralDto) {
    return data && `${data.code} - ${data.intitule}`;
  }

  async onTypeChanged(typeId?: number) {
    this.currentClassificationTypeId = typeId;
    if (typeId === null || typeId === undefined) {
      this.treeList.instance.clearFilter(TypeIdDatafield);
      this.currentClassificationType = null;
    } else {
      this.currentClassificationType = await lastValueFrom(this.classificationTypesLexiClient.getById(typeId));
      this.isTypeArticle = this.currentClassificationType.objectType === ObjectType.article;
      // la ligne suivante doit rester après les 2 précédentes
      // car la màj de this.currentClassificationType re-initialise la treelist
      // le filtre doit être appliqué après l'init de la treeList
      this.treeList.instance.filter([TypeIdDatafield, '=', typeId]);
    }
  }

  /**
   * Initialise la nouvelle classification avec le CodeBo du parent (si créée depuis un parent)
   */
  onInitNewRow(e: { data: { parentId: number } }) {
    let parentCodeBo = null;
    const item: ClassificationValueDto = e.data;
    item.niveau = 1;
    if (item) {
      const index = this.treeList.instance.getRowIndexByKey(item.parentId);
      if (index >= 0) {
        parentCodeBo = this.treeList.instance.cellValue(index, 'codeBo');
        const parentNiveau = this.treeList.instance.cellValue(index, 'niveau');
        item.niveau = parentNiveau + 1;
      }
    }
    item.codeBo = parentCodeBo;
    item.classificationTypeId = this.currentClassificationTypeId;
  }

  private setDataSources() {
    this.typesDatasource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypesService);
    this.setClassificationStore();
  }

  setClassificationStore() {
    this.classificationStore = new CustomStoreService(this.classificationService).getTreeListCustomStore();
  }

  onShowAddingPopup() {
    this.newClassificationValueRacine.classificationTypeId = this.currentClassificationTypeId;
    this.newClassificationValueRacine.codeBo = this.currentClassificationType.codeBo;
    this.newClassificationValueRacine.niveau = 1;
    this.showPopup = true;
  }

  onCancelAddingClassificationRacine() {
    this.showPopup = false;
    this.newClassificationValueRacine = {};
  }

  async onCreateNewClassificationRacine() {
    const isValid = this.formNewClassificationRacine.instance.validate().isValid;
    if (!isValid) { return; }
    await lastValueFrom(this.classificationValuesLexiClient.create(this.newClassificationValueRacine));
    this.onCancelAddingClassificationRacine();
    this.treeList.instance.refresh();
  }

  /**
   * Customize toolbar
   */
  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(item => {
      if (item.name === 'columnChooserButton') {
        item.options.hint = 'Choisir les comptes';
      }
    });
  }

  async onKeyDown(e) {
    if (!!this.currentClassificationTypeId && e.event.key === "Insert") {
        this.treeList.instance.addRow();
    }
  }

  async downloadTemplate() {
    try {
      this.recuperationTemplateEncours = true;
      const response = await lastValueFrom(this.classificationValuesLexiClient.getTemplateImportCsv('response'));
      this.downloadService.directDownloadFile(response, 'csv');
    }
    finally {
      this.recuperationTemplateEncours = false;
    }
  }

  async importDataFromCsv() {
    this.resetImportResultMsg();
    this.showLoader = true;
    try {
      const response: CsvImportResultDto = await lastValueFrom(this.classificationValuesLexiClient.importCsv(this.fichierCsv[0]));

      // Erreur
      if(response.hasFailed) {
        this.importHasFailed = true;
        if (response.errorMessage != null) {
          this.importCsvResultMsg = response.errorMessage;
        }

        else {
          if (response.hasWrongHeadersCount)
          {
            this.importCsvResultMsg = "Le nombre de colonnes est incorrecte, il y a plus de colonnes que celles attendues.";
          }

          else
          {
            this.importCsvResultMsg = `Les colonnes suivantes sont invalides: ${response.invalidHeaders.join(', ')}.`;
          }

          if (response.expectedHeaders != null)
          {
            this.importCsvResultMsg2 = `Colonnes attendues: (${response.expectedHeaders.join(', ')})`;
          }
        }
      }

      else if (response.itemsOnError.length > 0) {
        let errorMessage = "";
        response.itemsOnError.forEach(x => errorMessage += `${x}\n`);
        this.importCsvResultMsg = `Certaines éléments n'ont pas pu être importées. Le détail a été copié dans le presse papier`;
        this.importCsvResultMsg2 = "Importation incomplète";
        navigator.clipboard.writeText(errorMessage).then().catch(e => console.error(e));
        this.setClassificationStore();
      }

      // Succès
      else {
        this.importHasFailed = false;
        this.setClassificationStore();
        this.importCsvResultMsg = `${response.nbItemsCreated} élément(s) ont été créé(s), ${response.nbItemsUpdated} élément(s) ont été mis à jour.\nL'importation s'est terminée.`;
      }

      this.fichierCsv = [];
    }
    finally {
      this.showLoader = false;
    }
  }

  cancelImportCsv() {
    this.showImportCsvPopup = false;
    this.resetImportResultMsg();
    this.fichierCsv = [];
  }

  resetImportResultMsg() {
    this.importCsvResultMsg = null;
    this.importCsvResultMsg2 = null;
  }
}
