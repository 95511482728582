<!-- Entête -->
<dx-box *ngIf="isQuery" direction="row" width="100%" [height]="50">
  <dxi-item [ratio]="1">
    <dx-button backButton stylingMode="text" icon="back"></dx-button>
  </dxi-item>

  <dxi-item [ratio]="1">
    <h3>
      {{ this.objectType }} - {{ this.objectId }}
    </h3>
  </dxi-item>

  <dxi-item [ratio]="1"></dxi-item>
</dx-box>

<div style="display: flex;  align-items: center; margin: 0 0 0.5em 0.5em;">
  <dx-button *ngIf="!isAddFile" icon="refresh" (onClick)="this.setDocuments()" style="margin-right: 6px;" ></dx-button>
  <dx-file-uploader
    width="500"
    selectButtonText="Ajouter un fichier"
    labelText="ou Glisser/Déposer le fichier"
    accept="*"
    uploadMode="useForm"
    [allowCanceling]="true"
    [multiple]="false"
    [(value)]="this.files"
    (change)="onAddFileChanged($event)"
    style="margin-top: 5px;"
  ></dx-file-uploader>

  <dx-form style="display: flex; vertical-align: bottom;  align-items: center; margin: 0 0 1.5em 1.5em;" *ngIf="isAddFile" #form [(formData)]="this.addFileFormData" colCount="4">
      <dxi-item colSpan="2"
        [width]="300"
          dataField="classementDetailId"
          [label]="{text: ' Classement du document '}"
          text="Classement"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classementDetails,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: false,
            text: 'Classement',
            width: '150'
          }"
      ></dxi-item>

      <dxi-item dataField="isLink" [visible]="false" [editorOptions]="{text:'Link'}" [label]="{visible: false}" editorType="dxCheckBox"></dxi-item>

      <dxi-item dataField="officiel" [visible]="true" [editorOptions]="{text:'Officiel'}" [label]="{visible: false}" editorType="dxCheckBox"></dxi-item>
  </dx-form>
</div>

<div *ngIf="isAddFile" style="display: flex;  align-items: center; margin: 0 0 1em 0.5em;">
  <dx-button text="Annuler" style="margin-right: 10px; margin-left: 10px;" (onClick)="onAddCancel($event)"></dx-button>
  <dx-button text="Ajouter le fichier" (onClick)="onConfirmAddDocument($event)"></dx-button>
</div>

<dx-tree-list #treeList
  [dataSource]="documents"
  keyExpr="id"
  parentIdExpr="classementId"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [showBorders]="true"
  [showColumnHeaders]="false"
  (onRowRemoved)="onRowRemoved($event)"
>

  <!-- Dragging -->
  <dxo-row-dragging
    [onDragStart]="onDragStart"
    [onDragChange]="onDragChange"
    [onReorder]="onReorder"
    [allowDropInsideItem]="allowDropInsideItem"
    [allowReordering]="allowReordering"
    [showDragIcons]="showDragIcons"
  ></dxo-row-dragging>

  <!-- Edition -->
  <dxo-editing mode="popup" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="true"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="id" caption="Id" [visible]="false"></dxi-column>
  <dxi-column dataField="intitule" caption="Intitulé" [visible]="false"></dxi-column>
  <dxi-column dataField="classementId" caption="Classement Id" [visible]="false"></dxi-column>
  <dxi-column dataField="documentType" caption="Document Type" [visible]="false"></dxi-column>
  <dxi-column dataField="classementIntitule" caption="Classement Intitule" [visible]="false"></dxi-column>
  <dxi-column dataField="fileName" caption="Documents" [visible]="true"></dxi-column>
  <dxi-column dataField="description" caption="description" [visible]="false"></dxi-column>
  <dxi-column dataField="contentType" caption="contentType" [visible]="false"></dxi-column>
  <dxi-column dataField="path" caption="path" [visible]="false"></dxi-column>
  <dxi-column dataField="isLink" caption="isLink" [visible]="false"></dxi-column>
  <dxi-column dataField="officiel" caption="officiel" [visible]="false"></dxi-column>
  <dxi-column dataField="createdOn" caption="createdOn" [visible]="false"></dxi-column>
  <dxi-column dataField="modifiedOn" caption="modifiedOn" [visible]="false"></dxi-column>
  <dxi-column dataField="createdBy" caption="createdBy" [visible]="false"></dxi-column>
  <dxi-column dataField="modifiedBy" caption="modifiedBy" [visible]="false"></dxi-column>
  <dxi-column dataField="objectType" caption="objectType" [visible]="false"></dxi-column>
  <dxi-column dataField="objectId" caption="objectId" [visible]="false"></dxi-column>
  <dxi-column dataField="name" caption="name" [visible]="false"></dxi-column>
  <dxi-column type="buttons">
    <dxi-button
      hint="Télécharger le document"
      icon="download"
      [visible]="isIconVisible"
      [onClick]="onDownloadDocument"
    ></dxi-button>
    <dxi-button name="edit" [onClick]="setUpdatePopup" [visible]="isIconVisible"></dxi-button>
    <dxi-button name="delete" [visible]="isIconVisible"></dxi-button>
</dxi-column>

</dx-tree-list>

<!-- popup d'update d'un document -->
<dx-popup
  title="Modifier un document"
  [(visible)]="this.updatePopupVisible"
  [hideOnOutsideClick]="true"
  (onHiding)="hideUpdatePopup()"
  contentTemplate="updatePopup-content"
  [width]="600"
  [height]="400"
>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
        text: 'Confirmer',
        type: 'success',
        onClick: onConfirmUpdateDocument
    }"
  ></dxi-toolbar-item>

  <dxi-toolbar-item
        widget="dxButton"
        location="after"
        toolbar="bottom"
        [options]="{
            text: 'Annuler',
            onClick: hideUpdatePopup
        }"
  ></dxi-toolbar-item>

  <div *dxTemplate="let data of 'updatePopup-content'">
    <dx-form #form [(formData)]="this.updateFileFormData" [colCount]="2">

      <dxi-item name="select-files" [visible]="false" colSpan="2">
        <dxo-label text="Sélection du fichier à modifier"></dxo-label>
        <div *dxTemplate>
          <dx-file-uploader
            selectButtonText="Choisir un fichier"
            labelText="ou Glisser/Déposer le fichier"
            accept="*"
            uploadMode="useForm"
            [allowCanceling]="true"
            [multiple]="false"
            [(value)]="this.files"
            (change)="onUpdateFileChanged($event)"
          ></dx-file-uploader>
        </div>
      </dxi-item>

      <dxi-item itemType="group" caption="Informations Fichier">
        <dxi-item dataField="intitule" [label]="{text: 'Nom'}"></dxi-item>

        <dxi-item dataField="contentType" [visible]="false" [editorOptions]="{text:''}" [label]="{text: 'Type de contenu'}"></dxi-item>

        <dxi-item dataField="path" [visible]="false"></dxi-item>

       </dxi-item>

       <dxi-item itemType="group" caption="Informations Document" colSpan="2">

        <dxi-item dataField="objectId" [visible]="false"></dxi-item>
        <dxi-item dataField="objectType" [visible]="false"></dxi-item>

        <dxi-item
          dataField="classementId"
          [label]="{text: 'Classement du document'}"
          text="Classement"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classementDetails,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: false,
            text: 'Classement'
          }"
        ></dxi-item>

        <dxi-item itemType="group" [colCount]="2">
          <dxi-item dataField="officiel" [visible]="true" [editorOptions]="{text:'Officiel'}" [label]="{visible: false}" editorType="dxCheckBox"></dxi-item>
          <dxi-item dataField="isLink" [visible]="false" [editorOptions]="{text:'Link'}" [label]="{visible: false}" editorType="dxCheckBox"></dxi-item>
        </dxi-item>

        <dxi-item dataField="name" [visible]="false"></dxi-item>
        <dxi-item dataField="classementId" [visible]="false"></dxi-item>
        <dxi-item dataField="content" [visible]="false"></dxi-item>
      </dxi-item>
    </dx-form>
  </div>
</dx-popup>