<div id="caracteristiques">
  <dx-data-grid #dataGrid *ngIf="dataSource" id="article-list-datagrid-main-content" [dataSource]="dataSource"
    [allowColumnReordering]="false" [columnAutoWidth]="false" [allowColumnResizing]="true" [showBorders]="true"
    (onRowInserting)="onRowInserting($event.data)" (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event.key)" (onKeyDown)="onKeyDown($event)">
    <!-- Toolbar -->
    <dxo-toolbar>
      <dxi-item name="addRowButton"></dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-scrolling showScrollbar="always"></dxo-scrolling>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-editing [allowUpdating]="canModifierCaracteristiques" [allowDeleting]="canModifierCaracteristiques"
      [allowAdding]="canModifierCaracteristiques" mode="row"></dxo-editing>

    <!-- Colonnes -->
    <dxi-column dataField="caracteristique.caracteristiqueTypeIntitule" caption="Type" [allowEditing]="false"
      [visible]="true" groupIndex="0"></dxi-column>
    <dxi-column dataField="caracteristique.intitule" caption="Caractéristique" [allowEditing]="false"
      [visible]="true"></dxi-column>
    <dxi-column dataField="valeur" caption="Valeur" [visible]="true"></dxi-column>
  </dx-data-grid>
</div>