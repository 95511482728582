import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxButtonModule, DxDataGridModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTemplateModule, DxToolbarModule } from "devextreme-angular";
import { UserPanelModule } from "..";
import { HeaderBaseComponent } from "./header-base.component";
import { HeaderPartenaireComponent } from "./header-partenaire.component";
import { HeaderComponent } from "./header.component";
import { HeaderDirective } from "./header.directive";
import { AppRoutingModule } from "lexi-angular/src/app/app-routing.module";
import { SharedModule } from "lexi-angular/src/app/modules/shared/shared.module";
import { ThemeSwitcherModule } from "../theme-switcher/theme-switcher.component";

@NgModule({
    declarations: [
      HeaderBaseComponent,
      HeaderComponent,
      HeaderPartenaireComponent,
      HeaderDirective
    ],
    imports: [
      AppRoutingModule,
      CommonModule,
      SharedModule,
      DxButtonModule,
      DxDataGridModule,
      UserPanelModule,
      DxSelectBoxModule,
      DxToolbarModule,
      DxPopupModule,
      DxTemplateModule,
      DxScrollViewModule,
      ThemeSwitcherModule
    ],
    exports: [
      HeaderBaseComponent,
      HeaderComponent,
      HeaderPartenaireComponent,
      HeaderDirective
    ]
  })
  export class HeaderModule { }
