import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocietesLexiClient, Permissions } from '@lexi-clients/lexi';
import DataSource from 'devextreme/data/data_source';
import { PrixListService } from 'lexi-angular/src/app/services/prix.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-valorisation-stock',
  templateUrl: './valorisation-stock.component.html',
  styleUrls: ['./valorisation-stock.component.scss'],
})
export class ValorisationStockComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly FILTRES_STORAGE_KEY = "STOCK_VIEW_FILTRES";
  readonly SWITCH_STORAGE_KEY = "STOCK_VIEW_SHOW_LIEUX_STOCKAGE";
  readonly PRICE_STORAGE_KEY = "STOCK_VIEW_PRICE";

  currentSiteId: number;
  filtres: {  };

  /** Détermine si on affiche le stock entier de l'agence ou bien si on affiche le stock par lieu de stockage */
  showWithLieuStockages = false;

  isAffichagePMPAutorise: boolean = false;


  dateStock : Date = new Date();

  // Prix
  prixDataSource: DataSource;
  selectedPrixId: number;

  constructor(
    private readonly authService: AuthService,
    private readonly societesLexiClient: SocietesLexiClient,
    private readonly prixListService: PrixListService,
    private readonly dxDataSourceService: DxDataSourceService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.setFiltresOnInit();
    this.setShowWithLieuStockages();
    this.setPrixOnInit();
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => this.currentSiteId = siteId)
    );

    this.isAffichagePMPAutorise = this.authService.securityUserisGrantedWith(Permissions.canAfficherPmp);

    // Init des datasource
    this.prixDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.prixListService);
  }

  private setFiltresOnInit() {
    const storedFiltresString = localStorage.getItem(this.FILTRES_STORAGE_KEY);
    this.filtres = storedFiltresString ? JSON.parse(storedFiltresString) : {  };
  }

  private setShowWithLieuStockages() {
    const storedSwitchValueString = localStorage.getItem(this.SWITCH_STORAGE_KEY);
    this.showWithLieuStockages = storedSwitchValueString ? JSON.parse(storedSwitchValueString) : false;
  }

  private setPrixOnInit() {
    const storedPriceId = localStorage.getItem(this.PRICE_STORAGE_KEY);
    if (storedPriceId && !isNaN(Number(storedPriceId))) {
      this.selectedPrixId = Number(storedPriceId);
    }
  }

  onValueChanged = () => {
    localStorage.setItem(this.FILTRES_STORAGE_KEY, JSON.stringify(this.filtres));
  }

  onSwitchValueChanged() {
    localStorage.setItem(this.SWITCH_STORAGE_KEY, JSON.stringify(this.showWithLieuStockages));
  }

  onPrixChange(event) {
    if(event.value !== event.previousValue) {
      this.selectedPrixId = event.value;
      localStorage.setItem(this.PRICE_STORAGE_KEY, JSON.stringify(this.selectedPrixId));
    }
  }

  onDateChange(event: any) {
    this.dateStock = event.value;
  }

  protected readonly Date = Date;
}
