import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

/**
 * https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular
 */

/** Interface for object which can store both: 
 * An ActivatedRouteSnapshot, which is useful for determining whether or not you should attach a route (see this.shouldAttach)
 * A DetachedRouteHandle, which is offered up by this.retrieve, in the case that you do want to attach the stored route
 */
export class CustomReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (!route) return false;
        return route.data['reuseRoute'] && !this.storedRouteHandles.has(this.getPath(route));
    }

    //Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const path = this.getPath(route);

        if (handle === null) {
            this.storedRouteHandles.delete(path);
        } else { this.storedRouteHandles.set(path, handle); }
    }

    //Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!route.data['reuseRoute'] && this.storedRouteHandles.has(this.getPath(route));
    }

    //If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRouteHandles.get(this.getPath(route));
    }

    getPath(route: ActivatedRouteSnapshot) {
        if(!route) return null;
        return route.pathFromRoot.flatMap(p => p.url).join('/');
    }

    //Reuse the route if we're going to and from the same route
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return this.getPath(future) === this.getPath(curr);
    }
}