import { Component, ViewChild } from '@angular/core';
import { SocietesLexiClient, SocieteToCreateDto } from '@lexi-clients/lexi';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { ValidationResult } from 'devextreme/ui/validation_group';
import { lastValueFrom } from 'rxjs';
import { NotificationType } from '../../modules/shared/references/references';
import { Router } from '@angular/router';
import { AuthService } from '../../settings/auth.service';


@Component({
  selector: 'app-creation-societe',
  templateUrl: './creation-societe.component.html',
  styleUrls: ['./creation-societe.component.scss'],
})
export class CreationSocieteComponent {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  societeToCreate: SocieteToCreateDto = {};
  codeDossier: string;
  requeteServeurEnCours = false;

  constructor(
    private readonly societeService : SocietesLexiClient,
    private readonly router: Router,
    private readonly auth: AuthService
  ) {}

  async creer() {
    try {
      const formValidationResult: ValidationResult = this.form.instance.validate();
      if (!formValidationResult.isValid) return;

      // Création de la nouvelle société
      this.requeteServeurEnCours = true;
      const s = await lastValueFrom(this.societeService.create(this.societeToCreate));
      notify({ closeOnClick: true, message: "La société a été créé avec succès. Changement de société..." }, NotificationType.Success);
      this.router.navigate(['']);

      // Redirection vers la nouvelle société créée
      await this.auth.refreshAvailableSocietesFromServer(this.auth.securityUser?.id);
      await this.auth.setSocieteId(s.id, true);
    }
    finally {
      this.requeteServeurEnCours = false;
    }
  }

  inscrire() {
    
  }
}
