import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent, DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';
import { NavigationTop, navigationCollaborateur, navigationPartenaire, Parametrage, Navigation, NavigationCollaborateurMenuId } from '../../../models/navigation-top';
import * as events from 'devextreme/events';
import { ModuleService } from '../../services/module.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { ParametrageRevatuaLexiClient, TypeUtilisateur } from '@lexi-clients/lexi';
import { AppInfoService } from '../../services';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SocieteService } from 'lexi-angular/src/app/services/societe.service';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import { CommonModule } from '@angular/common';
import { Permissions } from '@lexi-clients/lexi';
import { InternationalisationPipe } from 'lexi-angular/src/app/modules/shared/pipes/internationalisation.pipe';
import { Subscription, filter } from 'rxjs';
import { AppFooterModule } from '../app-footer/app-footer.component';
import { FeatureFlagService } from "../../services/feature-flag.service";

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem: string;
  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;
    this.setSelectedItem();
  }

  items: Navigation[] = [];
  private flattenItems: Navigation[] = [];

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  isPartenaire = false;
  navPrincipal: Array<NavigationTop> =[];

  navigationOrganisation: Navigation = {
    text: 'Organisation',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Société', path: '/administration/infos-societe', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Sites', path: '/administration/sites', permissionsRequises: [Permissions.canManageSite], visible: true, items: [] } as Navigation,
      { text: 'Équipes', path: '/administration/equipes', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Lieux de stockage', path: '/logistique/lieux-de-stockage', visible: true, permissionsRequises: [Permissions.canGererLieuStockages], items: [] } as Navigation,
    ]
  };

  navigationClient: Navigation = {
    text: 'Clients / Fournisseurs',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Clients', path: '/clients', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Fournisseurs', path: '/fournisseurs', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Partenaires', path: '/partenaires', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Classifications', path: '/administration/classifications/clients', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Formes Juridiques', path: '/administration/formes-juridiques', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Profils Partenaires', path: '/administration/profils-partenaires', permissionsRequises: [], visible: true, items: [] } as Navigation,
    ]
  };


  navigationArticle: Navigation = {
    text: 'Articles',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Articles', path: '/articles', visible: true, permissionsRequises: [], items: [] } as Navigation,
      { text: 'Classifications', path: '/administration/classifications/articles', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Groupes de taxes', path: '/administration/groupes-taxe', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
      { text: 'Taxes', path: '/administration/taxes', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
      { text: 'Unités', path: '/administration/unites', permissionsRequises: [], visible: true, items: [] } as Navigation,
    ]
  };

  navigationGenerale: Navigation = {
    text: 'Paramètres généraux',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Process', path: '/administration/gestion-des-process', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
      { text: 'Stratégies de comptage', path: '/administration/strategies-comptage', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Types de mouvement', path: '/administration/types-de-mouvement', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Types d\'évènement', path: '/administration/gestion-evenement-type', visible: true, permissionsRequises: [Permissions.canManageSite], items: [] } as Navigation,
      { text: 'Caractéristiques', path: '/administration/caracteristiques', visible: true, permissionsRequises: [], items: [] } as Navigation,
      { text: 'Types de caractéristique', path: '/administration/types-caracteristique', visible: true, permissionsRequises: [], items: [] } as Navigation,
      { text: 'Codes avantages', path: '/administration/codes-avantage', visible: true, permissionsRequises: [], items: [] } as Navigation,
      { text: 'Nature lieu stockage', path: '/administration/lieux-stockage-nature', visible: true, permissionsRequises: [], items: [] } as Navigation,
    ]
  };

  navigationTerminaux: Navigation = {
    text: 'Terminaux',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Terminaux', path: '/administration/terminaux', visible: true, permissionsRequises: [Permissions.canManageTerminaux], items: [] } as Navigation,
      { text: 'BackOffices', path: '/administration/backoffices', visible: true, permissionsRequises: [], items: [] } as Navigation,
    ]
  };

  navigationVente: Navigation = {
    text: 'Vente',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Modes de règlement', path: '/administration/modes-de-reglement', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Monnaies', path: '/administration/monnaies', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
      { text: 'Motifs annulation', path: '/administration/motifs-annulation', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Motifs retour', path: '/administration/motifs-retour', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Nature des pièces', path: '/administration/gestion-magasin-piece-nature', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
      { text: 'Types de remise', path: '/administration/types-de-remise', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Prix', path: '/administration/prix', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Tarifications', path: '/administration/tarifications', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Processus de facturation', path: '/administration/processus-facturations', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
    ]
  };

  navigationSecurityConfig: Navigation = {
    text: 'Sécurité',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Utilisateurs', path: '/securite/utilisateurs', permissionsRequises: [Permissions.canManageUsers], visible: true, items: [] } as Navigation,
      { text: 'Rôles', path: '/securite/roles', permissionsRequises: [Permissions.canManageRoles], visible: true, items: [] } as Navigation,
      { text: 'Affecter rôles', path: '/securite/affecter-roles', permissionsRequises: [Permissions.canAssignRoles], visible: true, items: [] } as Navigation,
    ]
  };

  navigationEditionConfig: Navigation = {
    text: 'Éditions',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Éditions', path: '/editions', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Édition Mappings', path: '/editions/mappings', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Process édition', path: '/administration/gestion-process-edition', permissionsRequises: [Permissions.canGererTpv], visible: true, items: [] } as Navigation,
      { text: 'Affecter process édition', path: '/administration/affecter-process-edition', permissionsRequises: [Permissions.canGererTpv], visible: true, items: [] } as Navigation,
    ]
  };

  navigationTechniqueConfig: Navigation = {
    text: 'Suivi',
    path: null,
    icon: 'activefolder',
    permissionsRequises: [],
    visible: true,
    items: [
      { text: 'Audit', path: '/administration/audit', permissionsRequises: [Permissions.canManageUsers], visible: true, items: [] } as Navigation,
      { text: 'Logs', path: '/administration/logs', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Activité utilisateur', path: '/administration/activite-utilisateur', permissionsRequises: [], visible: true, items: [] } as Navigation,
      { text: 'Message', path: '/administration/message', permissionsRequises: [Permissions.accesVueMessage], visible: true, items: [] } as Navigation,
    ]
  };

  navigationAdministrationConfig = [
    this.navigationOrganisation,
    this.navigationArticle,
    this.navigationClient,
    this.navigationEditionConfig,
    this.navigationVente,
    this.navigationGenerale,
    this.navigationTerminaux,
    this.navigationSecurityConfig,
    this.navigationTechniqueConfig,
    { text: 'Portail client', path: '/administration/portail-client', icon: 'hierarchy', permissionsRequises: [Permissions.canManagerSocietes], visible: true, items: [] } as Navigation,
  ];

  private subscription = new Subscription();

  constructor (
    private elementRef: ElementRef,
    private moduleService: ModuleService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    public appInfo: AppInfoService,
    private readonly customSocieteService: SocieteService,
    private readonly parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
    private readonly internationalisationPipe: InternationalisationPipe,
    private readonly featureTagService : FeatureFlagService,
    private readonly router: Router
  ) {
    this.moduleService.currentModule.asObservable().subscribe(async x => {
      if (x == NavigationCollaborateurMenuId.administration) {
        this.loadAdministrationMenu();
      }
      else {
        await this.loadModule(x)
      }


    if (!this.menu.instance)
      this.menu.instance.expandAll();
    });
  }

  private loadAdministrationMenu() {

    // config
    if(this.authService.securityUser?.isRoot) {
      this.items = this.navigationAdministrationConfig;
    }
    else {
      // Si l'utilisateur n'est pas root,
      // on filtre les onglets accessibles dans le menu
      this.items = this.setNavigationAdministrationConfig();
    }

    for(const item of this.items) {
      item.text = this.internationalisationPipe.transform(item.text);
      for(const subitem of item.items) {
        subitem.text = this.internationalisationPipe.transform(subitem.text);
      }
    }

    this.flattenItems = this.getFlattenItems(this.items);
  }

  async loadModule(moduleId: number) {
    if(this.authService.securityUser?.typeUtilisateur === TypeUtilisateur.collaborateur.toLowerCase()) {
      const parametrage: Parametrage = {
        currentUserType: await this.authService.getStoredUserTypeRuunui(),
        currentUserCanGererTransporteurs: this.authService.securityUserisGrantedWith(Permissions.canGererTransporteurs),
        currentSocieteManageStock: this.authService.currentSociete != null && this.authService.currentSociete.gestionStock
      }
      this.navPrincipal = navigationCollaborateur(parametrage, this.featureTagService);
    }

    else {
      this.isPartenaire = true;
      let societe = this.customSocieteService.getSocietePortailClient();
      if(!societe) {
       await this.customSocieteService.setSocietePortailClient();
        societe = this.customSocieteService.getSocietePortailClient();
      }
      this.navPrincipal = navigationPartenaire(societe);
    }

    const nav = this.navPrincipal.find(i => i.id === moduleId);

    if (nav) {
      // On set les items et on set les propriétés expanded et path des navigations parentes
      this.items = nav.navigation.map((item) => {
        if (item && item.path && !(/^\//.test(item.path))) {
          item.path = `/${item.path}`;
        }

        return { ...item, expanded: !this._compactMode && item.expanded !== false };
      });

      // Fonction locale pour recalculer de manière récursive les propriétés visible et text pour chaque navigation
      const updateNavigationItems = (items: Navigation[]): Navigation[] => {
        return items.map(x => {
          // On s'assure que ces valeurs ne soient pas null
          x.items ??= [];
          x.permissionsRequises ??= [];

          // Récursivité pour traiter les enfants en premier
          if (x.items.length > 0) {
            x.items = updateNavigationItems(x.items);
          }

          // Set de la propriété visible
          const isItemSansEnfants = x.visible && x.items.length == 0;
          const aAuMoinsUnEnfantVisible = x.visible && x.items.some(x => x.visible);
          const userHasPermissions = x.permissionsRequises.every(p => this.authService.securityUserisGrantedWith(p));
          x.visible = userHasPermissions && (isItemSansEnfants || aAuMoinsUnEnfantVisible);

          // Internationnalisation du texte
          x.text = this.internationalisationPipe.transform(x.text);

          return x;
        });
      };

      // Recalcul des propriétés
      this.items = updateNavigationItems(this.items);

      this.flattenItems = this.getFlattenItems(this.items);
    }

    this.cdr.markForCheck();
  }

  onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  onContentReady(e: any) {
    const scrollWidget = Scrollable.getInstance(e.element.querySelector('.dx-scrollable'));
    if(scrollWidget) {
      // documentation : https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxScrollView/Configuration/
      scrollWidget.option('showScrollbar', 'always'); // On affiche la scrollbar si le contenu est "scrollable"
    }
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      this.openMenu.next(e);
    });

    this.setSelectedItem();
  }

  private getFlattenItems(items: Navigation[]): Navigation[] {
    return items.flatMap(i => i.items?.length > 0 ? [i].concat(this.getFlattenItems(i.items)) : [i]);
  }

  private setSelectedItem() {
    if (!this.menu.instance) {
      return;
    }
    this.menu.instance.selectItem(this._selectedItem);
    this.menu.instance.expandItem(this._selectedItem);
  }
  /** Region setNavigationAdministration */
  private setNavigationAdministrationConfig() {

    const config: Navigation[] = [];

    /* Filtre securite/roles, securite/utilisateurs, securite/affecter-roles */
    const currentUserCanManageUsers = this.authService.securityUserisGrantedWith(Permissions.canManageUsers);
    const currentUserCanAssignRoles = this.authService.securityUserisGrantedWith(Permissions.canAssignRoles);

    // On ajoute la sécurité dans le menu
    if (currentUserCanManageUsers || currentUserCanAssignRoles) {
      const securityConfigCopy = { ...this.navigationSecurityConfig };
      config.push(securityConfigCopy);

      // Exclure les onglets non-autorisés de la sécurité
      if (config.length > 0) {
        const lastItem = config[config.length - 1];

        // Seul root peut gérer les permissions des roles, on supprime par défaut
        this.removeNavigationItemByPath(lastItem.items, '/securite/roles');

        if (!currentUserCanManageUsers)
        this.removeNavigationItemByPath(lastItem.items, '/securite/utilisateurs');

        if (!currentUserCanAssignRoles)
        this.removeNavigationItemByPath(lastItem.items, '/securite/affecter-roles');
      }
    }

    /* Ajouter une autre entrée au menu de l'administration */
    // ...

    return config;
  }

  private removeNavigationItemByPath(items: Navigation[], pathToRemove: string): void {
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];

      if (item.path === pathToRemove) {
        // Si l'élément a le chemin spécifié
        // alors on le retire du tableau
        items.splice(i, 1);
      }
    }
  }
  /** Endregion setNavigationAdministration */


  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
    this.subscription.unsubscribe();
  }
}

@NgModule({
  imports: [ DxTreeViewModule, AppFooterModule, RouterModule, CommonModule ],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ]
})
export class SideNavigationMenuModule { }
