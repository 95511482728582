<div *ngIf="isFromSociete" style="height: 24px; background-color: #FFF3CD;">
  <span style="color: grey;">La configuration logistique ci-dessous provient de la société en cours</span>
</div>
<dx-form [formData]="logistiqueSettings" class="form last plain-styled-form" labelMode="floating" [colCount]="colCount" [readOnly]="formIsReadOnly">
  
  <dxi-item [colCount]="2" [colSpan]="colCount" itemType="group" caption="Réception">
    <dxi-item [colSpan]="1" dataField="reception.activerIntegrationBackOffice" editorType="dxCheckBox" [editorOptions]="{ text: 'Intégration back office' }" [label]="{visible: false}"></dxi-item>
    <dxi-item [colSpan]="1" dataField="reception.defaultMouvementTypeCodeBo" [label]="{ text: 'Code mouvement type par défaut' }"></dxi-item>
  </dxi-item>

  <dxi-item [colCount]="4" [colSpan]="colCount" itemType="group" caption="Inventaire">
    <dxi-item [colSpan]="2" dataField="inventaire.codeMouvementInventaireGeneral" [label]="{ text: 'Code mouvement inventaire général' }"></dxi-item>
    <dxi-item [colSpan]="2" dataField="inventaire.codeMouvementInventaireTournant" [label]="{ text: 'Code mouvement inventaire tournant' }"></dxi-item>
    <dxi-item [colSpan]="1" dataField="inventaire.useDirectFTP" editorType="dxCheckBox" [editorOptions]="{ text: 'Utiliser FTP' }" [label]="{visible: false}"></dxi-item>
    <dxi-item [colSpan]="3" dataField="inventaire.targetFolderPath" [label]="{ text: 'Chemin' }"></dxi-item>
  </dxi-item>
  <dxi-item [colCount]="4" [colSpan]="colCount" itemType="group" caption="Préparation">
    <dxi-item [colSpan]="1" dataField="preparation.prixConventionneCodeBo" [label]="{ text: 'Code prix conventionné' }"></dxi-item>
    <dxi-item [colSpan]="1" dataField="preparation.unitesDePoids" editorType="dxTagBox" [label]="{ text: 'Unité de poids' }" [editorOptions]="{acceptCustomValue: 'true'}"></dxi-item>
    <dxi-item [colSpan]="2" dataField="preparation.zonePreparationsAvecDetailsPoids" editorType="dxTagBox" [label]="{ text: 'Zone de préparation avec détail de poids' }" [editorOptions]="{acceptCustomValue: 'true'}"></dxi-item>
    <dxi-item [colSpan]="1" dataField="preparation.prixDutyCodeBo" [label]="{ text: 'Code prix duty' }"></dxi-item>
    <dxi-item [colSpan]="3" dataField="preparation.defaultMouvementTypeCodeBo" [label]="{ text: 'Code mouvement type par défaut' }"></dxi-item>
  </dxi-item>
  <dxi-item [colCount]="1" [colSpan]="colCount" itemType="group" caption="Editions">
    <dxi-item [colSpan]="colSpan" dataField="edition.useBackOffice" editorType="dxCheckBox" [editorOptions]="{ text: 'Utiliser le back office' }" [label]="{visible: false}"></dxi-item>
    <dxi-item [colSpan]="1" label="Imprimantes">
        <dx-data-grid
          *ngIf="logistiqueSettings?.edition"
          [dataSource]="logistiqueSettings.edition.imprimantes"
          [showBorders]="true"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          (onKeyDown)="onKeyDown($event)"
          >
          <!-- Toolbar -->
          <dxo-toolbar>
            <dxi-item>
                <dx-button icon="refresh" stylingMode="text"  hint="Rafraîchir"></dx-button>
            </dxi-item>
            <dxi-item name="addRowButton"></dxi-item>
          </dxo-toolbar>
        
          <!-- Options -->
          <dxo-editing mode="row" [allowAdding]="!formIsReadOnly" [allowUpdating]="!formIsReadOnly" [allowDeleting]="!formIsReadOnly" [useIcons]="true"></dxo-editing>
        
          <dxi-column dataField="intitule" caption="Intitule"></dxi-column>
          <dxi-column dataField="adresseIp" caption="Adresse IP"></dxi-column>
          <dxi-column dataField="port" caption="Port" alignment="left"></dxi-column>
          <dxi-column dataField="cheminReseau" caption="Chemin Réseau"></dxi-column>
        </dx-data-grid>
    </dxi-item>
  </dxi-item>
</dx-form>