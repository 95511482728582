import { Component, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-reset-grid-state',
  templateUrl: './reset-grid-state.component.html',
  styleUrls: ['./reset-grid-state.component.scss'],
})
export class ResetGridStateComponent {
  @Input() dataGrid: DxDataGridComponent;
  @Input() stylingMode: "text" | "outlined" | "contained" = "text";

  onStateResetClick() {
    if (this.dataGrid == null) return;
    let result = confirm("Toute personnalisation d'affichage sur cette page sera perdue, voulez-vous procéder ?", "Confirmation");
    result.then((confirmed) => {
      if (confirmed) {
        this.dataGrid.instance.state(null);
      }
    });
  }
}
