<!-- DataGrid -->
<dx-data-grid id="gridFactures" [dataSource]="factures" [allowColumnReordering]="true" [columnAutoWidth]="true"
  [allowColumnResizing]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
  [rowAlternationEnabled]="true" (onSelectionChanged)="checkIsAnyRowSelected()"
  (onExporting)="onExporting($event, 'factures')" width="100%">
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        {{getTexteLignesSelectionees()}}
      </div>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setFactures()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-drop-down-button text="Télécharger PDF" icon="download" [items]="listeOptionsDeTelechargement"
          displayExpr="libelle" keyExpr="id" (onItemClick)="onDownloadPdfList($event)"
          [disabled]="!anyRowSelected"></dx-drop-down-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true"
    [visible]="true"></dxo-pager>

  <!-- Colonnes -->
  <dxi-column dataField="dossierCodeBoIntitule" caption="Dossier" sortOrder="desc" groupCellTemplate="groupTemplate"
    [visible]="false"></dxi-column>
  <dxi-column dataField="codeBo" [visible]="false" caption="Code" [width]="120"></dxi-column>
  <dxi-column dataField="noPiece" caption="Numéro" [width]="120" [allowGrouping]="false"
    cellTemplate="noPieceCellTemplate">
    <div *dxTemplate="let data of 'noPieceCellTemplate'">
      <a [routerLink]="'/vente/pieces/' + data?.data?.id" title="Voir le détail">
        {{data?.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="dateOperation" dataType="date" caption="Date" format="dd/MM/yyyy" [width]="118"></dxi-column>
  <dxi-column dataField="commentaire" caption="Commentaire" [visible]="false"></dxi-column>
  <dxi-column dataField="dateEcheance" caption="Échéance" dataType="date" format="dd/MM/yyyy"
    [width]="118"></dxi-column>
  <dxi-column dataField="montantHT" caption="HT" cellTemplate="montantTemplate" [width]="130"></dxi-column>
  <dxi-column dataField="montantTTC" caption="TTC" cellTemplate="montantTemplate" [width]="130"></dxi-column>
  <dxi-column dataField="montantTaxes" caption="TVA" [visible]="false" cellTemplate="montantTemplate" [width]="130"></dxi-column>
  <dxi-column dataField="montantSolde" caption="Solde" cellTemplate="montantTemplate" [width]="130"></dxi-column>

  <div *dxTemplate="let data of 'montantTemplate'">
    {{data.value | number:'1.0-0'}}
  </div>

  <dxi-column dataField="dossierCodeBo" caption="Code dossier" [visible]="false"></dxi-column>
  <dxi-column dataField="dossierIntitule" caption="Intitulé dossier" [width]="200"
    groupCellTemplate="groupTemplate"></dxi-column>
  <div *dxTemplate="let groupData of 'groupTemplate'">
    {{ groupData.displayValue || "Sans dossier" }}
  </div>

  <dxi-column type="buttons" [width]="50">
    <dxi-button hint="Télécharger" icon="exportpdf" [onClick]="onDownloadPdf"></dxi-button>
  </dxi-column>

  <!-- Totaux -->
  <dxo-summary>
    <dxi-total-item column="montantHT" summaryType="sum" valueFormat="fixedPoint"
      displayFormat="HT: {0}"></dxi-total-item>
    <dxi-total-item column="montantTTC" summaryType="sum" valueFormat="fixedPoint"
      displayFormat="TTC: {0}"></dxi-total-item>
    <dxi-total-item column="montantTaxes" summaryType="sum" valueFormat="fixedPoint"
      displayFormat="TVA: {0}"></dxi-total-item>
    <dxi-total-item column="montantSolde" summaryType="sum" valueFormat="fixedPoint"
      displayFormat="Solde: {0}"></dxi-total-item>
  </dxo-summary>
</dx-data-grid>