import { Injectable } from '@angular/core';

export enum FeatureFlags {
  VOIR_BONS_SITES_PERMIS = 'Voir tous les bons des sites permis',
  IMPORTER_LIGNES_BON_CSV = "Importer des lignes de bons via le csv"
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private featureFlags: FeatureFlags[] = [];

  constructor() {
    this.loadFeatureTags();
  }

  private loadFeatureTags(): void {
    const storedTags = localStorage.getItem('featureFlags');
    this.featureFlags = storedTags ? JSON.parse(storedTags) : [];
  }

  getFeatureTags(): FeatureFlags[] {
    return this.featureFlags;
  }

  updateFeatureTag(feature: FeatureFlags, isEnabled: boolean): void {
    if (isEnabled) {
      if (!this.featureFlags.includes(feature)) {
        this.featureFlags.push(feature);
      }
    } else {
      this.featureFlags = this.featureFlags.filter(f => f !== feature);
    }
    this.saveFeatureTags();
  }

  isFeatureEnabled(feature: FeatureFlags): boolean {
    return this.featureFlags.includes(feature);
  }

  private saveFeatureTags(): void {
    localStorage.setItem('featureFlags', JSON.stringify(this.featureFlags));
  }

  static canActivate(feature: FeatureFlags): boolean {
    const storedTags = localStorage.getItem('featureFlags');
    const featureTags = storedTags ? JSON.parse(storedTags) : [];
    return featureTags.includes(feature);
  }
}
