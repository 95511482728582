export enum PartenaireCategorieEnum {
  clients,
  fournisseurs,
  internes,
  externes,
  tous
}

export const partenaireCategories = [
  {enumId: PartenaireCategorieEnum.clients, text: 'Clients'},
  {enumId: PartenaireCategorieEnum.fournisseurs, text: 'Fournisseurs'},
  {enumId: PartenaireCategorieEnum.internes, text: 'Partenaires internes'},
  {enumId: PartenaireCategorieEnum.externes, text: 'Partenaires externes'},
  {enumId: PartenaireCategorieEnum.tous, text: 'Tous'},
];

export const partenaireCategoriesLibellesMap = new Map<number, string>([
  [PartenaireCategorieEnum.clients, "Clients"],
  [PartenaireCategorieEnum.fournisseurs, "Fournisseurs"],
  [PartenaireCategorieEnum.internes, "Partenaires"],
  [PartenaireCategorieEnum.externes, "Partenaires"],
  [PartenaireCategorieEnum.tous, "Partenaires"],
]);