<dx-toolbar>
  <dxi-item location="before"
    ><span [class]="titleClass">Détails</span>
  </dxi-item>
  <dxi-item location="after" locateInMenu="after" [visible]="!hideAllButtons && !isEditing">
    <dx-button
      text="Modifier"
      icon="edit"
      stylingMode="contained"
      type="default"
      (onClick)="handleEditClick()"
    ></dx-button>
  </dxi-item>
  <dxi-item location="after" locateInMenu="after" [visible]="!hideAllButtons && isEditing">
    <dx-button
      text="Enregistrer"
      stylingMode="contained"
      type="default"
      (onClick)="handleSaveButtonClick($event)"
    ></dx-button>
  </dxi-item>
  <dxi-item location="after" locateInMenu="after" [visible]="!hideAllButtons && isEditing">
    <dx-button
      text="Annuler"
      (onClick)="handleCancelEditClick()"
      stylingMode="outlined"
      type="normal"
    ></dx-button>
  </dxi-item>
</dx-toolbar>