<dx-data-grid
  id="classificationTypesDataGrid"
  [dataSource]="classificationTypes"
  keyExpr="id"
  [showBorders]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  (onRowInserting)="onCreateClassification($event)"
  (onRowUpdating)="onUpdateClassification($event)"
  (onRowRemoving)="onDeleteClassification($event)"
  (onInitNewRow)="onInitNewRow($event)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="loadClassifications()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code" [validationRules]="[{ type: 'required' }]" ></dxi-column>
  <dxi-column dataField="intitule" caption="Intitulé" [validationRules]="[{ type: 'required' }]"></dxi-column>
  <dxi-column dataField="objectType" caption="Type" [validationRules]="[{ type: 'required' }]"
    [allowFiltering]="objectType == null"
  >
    <dxo-lookup [dataSource]="objectTypesDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="niveau" caption="Avec Hiérarchie"></dxi-column>
</dx-data-grid>