
import { Injectable } from "@angular/core";
import { EtatRevatua, EtatRevatuaEnum } from "./revatua-etat";

@Injectable()
export class ReferencesConnaissementService {

  getEtatRevatua(): EtatRevatua[] {
    return [
      { id: EtatRevatuaEnum.SAISIE, libelle: 'Saisie', color: '', icon: 'dx-icon-file', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.MODIFICATION, libelle: 'Modification', color: '', icon: 'dx-icon-edit', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.DEMANDE, libelle: 'Demande', color: 'blue', icon: 'dx-icon-help blue', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.TRANSFERT, libelle: 'Transfert', color: 'blue', icon: 'dx-icon-tags', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.OFFICIALISE_SOUS_RESERVE, libelle: 'Officialisé sous réserve', color: 'blue', icon: 'dx-icon-info', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.OFFICIALISE, libelle: 'Officialisé', color: 'green', icon: 'dx-icon-check', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.BROUILLON, libelle: 'Brouillon', color: '', icon: 'dx-icon-file', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.EMBARQUE, libelle: 'Embarqué', color: 'green', icon: 'dx-icon-key', decoration:'', fontStyle:'', fontWeight: 450},
      { id: EtatRevatuaEnum.PRIS_EN_CHARGE, libelle: 'Pris en charge', color: 'green', icon: 'dx-icon-pin', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.ANNULE, libelle: 'Annulé', color: 'red', icon: 'dx-icon-revert', decoration:'line-through', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.DEMANDE_REFUSEE, libelle: 'Demande refusée', color: 'red', icon: 'dx-icon-remove', decoration:'', fontStyle:'', fontWeight: 400},
      { id: EtatRevatuaEnum.EMBARQUEMENT_REFUSE, libelle: 'Embarquement réfusé', color: 'red', icon: 'dx-icon-remove', decoration:'', fontStyle:'', fontWeight: 400},
    ];
  }

  getPopUpSize(smallWith, smallHeight, popupAction) {
    if (window.screen.width > 1536) {
      popupAction.width = 1536;
      popupAction.height = 750;
    } else {
      popupAction.width = smallWith;
      popupAction.height = smallHeight;
    }
  }  
}
  
export enum TypePopupVisibleEnum {
  AucunAffichage,
  TraitementEnvoiConnaissement,
  AffectationVoyageRevatua,
  TransfererConnaissement,
  AfficherDetailConnaissement,
  UpdateConnaissementBackOffice,
  SelectionPrestationComplementaire,
  ConfirmerFacturationConnaissement,
  FacturerConnaissement
}