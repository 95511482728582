import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ProfilPartenaireDto, PartenaireDto, PartenairesLexiClient, ProfilPartenairesLexiClient, PartenaireType } from '@lexi-clients/lexi';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { lastValueFrom } from 'rxjs';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-partenaire-form',
  templateUrl: './partenaire-form.component.html',
  styleUrls: ['./partenaire-form.component.scss'],
})
export class PartenaireFormComponent implements OnInit {
  private _partenaireData: PartenaireDto;
  get partenaireData() { return this._partenaireData; }

  @Input() set partenaireData(value: PartenaireDto) {
    this._partenaireData = value;
    if (value) {
      this.loadData();
    }
  }

  @Input() isLoading: boolean;
  private _isEditing = false;
  get isEditing() { return this._isEditing; }
  @Input() set isEditing(value: boolean) {
    this._isEditing = value;
    this.isEditingChange.emit(value);
  }
  @Output() isEditingChange = new EventEmitter<boolean>();

  savedData: PartenaireDto = null;

  profilPartenaires: ProfilPartenaireDto[];
  partenaireType = PartenaireType;

  // phone validation pattern
  phonePattern: any = /^[^a-zA-Z]*$/;
  phoneBrokePatternMessage: string = "Ce champ ne doit pas contenir de lettres";
  
  constructor(
    private readonly partenaireService: PartenairesLexiClient,
    private readonly profilPartenairesLexiClient: ProfilPartenairesLexiClient) { }

  async ngOnInit() {
    await this.loadSelectBox();
  }

  async loadData() {
  }

  async loadSelectBox() {
    forkJoin({
      profilsData: this.profilPartenairesLexiClient.getAll()
    }).subscribe({
      next: (data: any) => {
        this.profilPartenaires = data.profilsData;
      },
      error: (err: any) => {
      }
    });
  }

  handleEditClick() {
    this.savedData = { ...this.partenaireData };
    this.isEditing = true;
  }

  async handleSaveClick({ validationGroup }: DxButtonTypes.ClickEvent) {
    if (!validationGroup.validate().isValid) return;
    await lastValueFrom(this.partenaireService.update(this.partenaireData.id, this.partenaireData));
    this.isEditing = false;
    this.savedData = null;
  }

  handleCancelClick() {
    this.partenaireData = { ...this.savedData };
    this.isEditing = false;

  }
}