
export const environment = {
  production: true,
  personnalisationLogo: false,
  lexiVersion: 'PROD',
  baseServiceUrl: window.location.origin,
  useInternationalisation: true,
  showCreationPiece: true,
  kpiUrl: window.location.origin+ '/dashboards',
};
