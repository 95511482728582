<div style="padding: 12px;">
  <dx-form *ngIf="strategieComptage != null" [formData]="strategieComptage" [readOnly]="!isModificationEnCours || !canGererBons" [colCount]="2">
    <dxi-item itemType="group" [colCount]="2" caption="Stratégie de comptage">
      <!-- Stratégie Comptage -->
      <dxi-item itemType="group">
        <dxi-item dataField="afficherArticlesPrevus" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Articles Prevus', hint: 'Afficher les articles prévus'}"></dxi-item>
        <dxi-item dataField="afficherQuantitesPrevues" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Quantités Prevues', hint: 'Afficher les quantités prévues'}"></dxi-item>
        <dxi-item dataField="saisieNumeroSerie" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Saisie Numéro de série', hint: 'Pouvoir saisir les numéros de saisie'}"></dxi-item>
        <dxi-item dataField="saisieNumeroColis" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Saisie Numéro de colis', hint: 'Pouvoir saisir les numéros de colis'}"></dxi-item>
        <dxi-item dataField="saisieDlc" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Saisie Dlc', hint: 'Contrôle sur l\'ajout d\'article non prévué'}"></dxi-item>
        <dxi-item dataField="envoyerQuantitesNulles" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Envoyer Quantités Nulles', hint: 'Lors de l\'envoi des comptages, détermine on envoie les quantités nulles ou pas.'}"></dxi-item>
        <dxi-item dataField="autoriserComptageTerminal" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Autoriser Comptage sur Terminal', hint: 'Détermine si le comptage se fait avec des terminaux mobiles.'}"></dxi-item>
        <dxi-item dataField="activerMultiComptage" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Activer comptages simultanés', hint: 'Permet de compter un même bon à plusieurs.'}"></dxi-item>
        <dxi-item
          dataField="ajoutArticle"
          [label]="{text: 'Ajout Article'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: controleTypeDataSource,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule'],
            showDataBeforeSearch: true,
            hint: 'Contrôle sur l\'ajout d\'article non prévué'
          }"
        ></dxi-item>
        <dxi-item
          dataField="uniteParDefaut"
          [label]="{text: 'Unité par défaut'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: uniteTypeDataSource,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule'],
            showDataBeforeSearch: true,
            hint: 'Unité de comptage par défaut'
          }"
        ></dxi-item>
      </dxi-item>
      <!-- Options Numera -->
      <dxi-item itemType="group" label="optionsNumera" caption="Options Numera">
        <dxi-item dataField="actifNumera" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Actif Numera', hint: 'Est exploité dans le module Numera : Pointage du document à l\'aide de terminaux mobile.'}"></dxi-item>
        <dxi-item dataField="optionsNumera.afficheNavigationCarrousel" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Navigation Carousel', hint:'Afficher les boutons de navigation en mode carrousel'}"></dxi-item>
        <dxi-item dataField="optionsNumera.retourAutomatiqueListeArticle" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Retour Automatique Liste Article', hint:'Retourne à la liste des articles après validation de la saisie'}"></dxi-item>
        <dxi-item dataField="optionsNumera.afficherChampsCommentaire" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Champs Commentaire', hint: 'Afficher la zone de saisie commentaire'}"></dxi-item>
        <dxi-item dataField="optionsNumera.afficherChampsZone" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Champs Zone', hint: 'Affiche la zone de saisie zone de stockage'}"></dxi-item>
        <dxi-item dataField="optionsNumera.afficherChampsQuantiteRestante" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Champs Quantité Restante', hint: 'Afficher la quantité restant à préparer'}"></dxi-item>
        <dxi-item dataField="optionsNumera.afficherCommandeForcer" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Afficher Commande Forcer', hint: 'Afficher le bouton Forcer'}"></dxi-item>
        <dxi-item dataField="optionsNumera.avertirSiteDocumentDifferent" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Avertir Site Document Different', hint: 'Averti si le site du document est différent du site du terminal'}"></dxi-item>
        <dxi-item dataField="optionsNumera.activerControleReferenceArticle" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Contrôle Référence Article', hint: 'Si activé, ouvre la page Comptage Article après un scan. Sinon, incrémente directement la quantité après un scan.'}"></dxi-item>
        <dxi-item dataField="optionsNumera.focusQuantiteApresScan" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Focus Quantité Après Scan', hint: 'Positionne le curseur sur le champ quantité après un scan afin de pouvoir saisir directement la quantité'}"></dxi-item>
      </dxi-item>
    </dxi-item>
  </dx-form>
</div>