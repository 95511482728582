<dx-button text="Ajouter une note" stylingMode="contained" icon="add" (onClick)="onOpenAddNotePopup()"></dx-button>

<dx-list [dataSource]="dataSource" [ngClass]="containerClass" [noDataText]="''">
  <div *dxTemplate="let data of 'item'">
    <div class="dx-card note-card" [ngClass]="{'note-card-current-user': data.createdBy == currentUserId}">
      <div class="note-header">
        <span class="dx-form-group-caption">{{ data.createdUtilisateur }}</span>
        <span class="dx-field-item-label-text note-date">{{ data.createdOn | date:'dd/MM/yyyy HH:mm' }}</span>
      </div>
      <dx-text-area [minHeight]="100" [(value)]="data.content" [readOnly]="true" />
    </div>
  </div>
</dx-list>

<!-- Popup : Créer une note -->
<dx-popup
  [showCloseButton]="true"
  title="Créer une note"
  [(visible)]="showAddNotePopup"
  [width]="400"
  [height]="350"
>
  <dx-form #addNoteForm [formData]="newNoteDto">
    <dxi-item dataField="content" [label]="{text: 'Contenu'}" editorType="dxTextArea" [editorOptions]="{ height: 200 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
  </dx-form>

  <div style="margin-top: 12px;  position: absolute; bottom: 10px;">
    <dx-button text="Annuler" type="danger" (onClick)="showAddNotePopup = false" style="margin-right: 6px;"></dx-button>
    <dx-button text="Créer" type="success" (onClick)="onCreateNote()"></dx-button>
  </div>
</dx-popup>

<!-- Popup : Modifier une note -->
<dx-popup
  [showCloseButton]="true"
  title="Modification"
  [(visible)]="showEditNotePopup"
  [width]="400"
  [height]="400"
>
  <dx-form #editNoteForm [formData]="noteDtoToUpdate">
    <dxi-item dataField="content" [label]="{text: 'Contenu'}" editorType="dxTextArea" [editorOptions]="{ height: 200 }">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
  </dx-form>

  <div style="margin-top: 12px;">
    <dx-button text="Annuler" type="danger" (onClick)="showEditNotePopup = false" style="margin-right: 6px;"></dx-button>
    <dx-button text="Enregistrer" type="success" (onClick)="onUpdateNote()"></dx-button>
  </div>
</dx-popup>

<!-- Popup : Confirmer la suppression d'une note -->
<dx-popup
  [showCloseButton]="true"
  title="Suppression"
  [(visible)]="showDeleteNotePopup"
  [width]="400"
>
  <p style="font-size: 16px;">Souhaitez-vous vraiment supprimer ce process ?</p>
  <div style="float: right;">
    <dx-button text="Annuler" style="margin-right: 6px;" (onClick)="showDeleteNotePopup = false"></dx-button>
    <dx-button text="Supprimer" type="danger" (onClick)="onDeleteNote()"></dx-button>
  </div>
</dx-popup>