<!-- Tableau -->
<dx-data-grid
  [dataSource]="detailDevises"
  [showBorders]="true"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowRemoving)="onRowRemoving($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
        <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDevisesAndDetailDevises()"></dx-button>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="row" [allowAdding]="!siteReadOnly" [allowUpdating]="!siteReadOnly" [allowDeleting]="!siteReadOnly"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="deviseId" caption="Devise">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="devises" valueExpr="id" displayExpr="intitule"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="controleCaisse" dataType="boolean" caption="Contrôle {{'caisse' | internationalisation}}" [width]="115"></dxi-column>
  <dxi-column dataField="tauxChange" dataType="number" caption="Taux Change" [width]="100">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

</dx-data-grid>