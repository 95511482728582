import { Component, OnInit, ViewChild } from '@angular/core';
import { CsvImportResultDto, PartenaireType, PartenairesLexiClient } from '@lexi-clients/lexi';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { partenaireCategoriesLibellesMap, partenaireCategories, PartenaireCategorieEnum } from '../../../models/enums/partenaireCategorie';
import { ActivatedRoute } from '@angular/router';
import { DxDataSourceService } from '../../../shared/services/dx-data-source.service';
import { DownloadService } from '../../../services/download.service';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';
import { PartenaireNewFormComponent } from '../partenaire-new-form/partenaire-new-form.component';
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-partenaire-list',
  templateUrl: './partenaire-list.component.html',
  styleUrls: ['./partenaire-list.component.scss'],
})
export class PartenaireListComponent implements OnInit {
  @ViewChild('gridContainer', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild(PartenaireNewFormComponent, { static: false }) partenaireNewForm: PartenaireNewFormComponent;

  dataSource: CustomStore;
  initialized = false;
  showImportCsvPopup = false;
  pattern: string;
  fichierCsv: File[] = [];
  importCsvResultMsg: string;
  importCsvResultMsg2: string;
  importHasFailed = false;
  showLoader = false;
  partenaireCategorie = PartenaireCategorieEnum.clients;
  partenaireCategories = partenaireCategories;
  isPartenaireCategorieFige: boolean = true;
  recuperationTemplateEncours = false;
  showLegende = false;

  constructor(
    private readonly partenairesLexiClient: PartenairesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly partenaireService: PartenairesLexiClient,
    private readonly downloadService: DownloadService
  ) { }

  ngOnInit() {
    this.activatedRoute.url.subscribe(urlSegment => {
      const partenaireCategorieFromUrl: string = urlSegment[0].path;
      switch (partenaireCategorieFromUrl) {
        case "clients": {
          this.partenaireCategorie = PartenaireCategorieEnum.clients;
          this.isPartenaireCategorieFige = true;
          break;
        }
        case "fournisseurs": {
          this.partenaireCategorie = PartenaireCategorieEnum.fournisseurs;
          this.isPartenaireCategorieFige = true;
          break;
        }
        case "partenaires": {
          this.partenaireCategorie = PartenaireCategorieEnum.internes;
          this.isPartenaireCategorieFige = false;
          this.partenaireCategories = partenaireCategories.filter(x => x.enumId !== PartenaireCategorieEnum.clients && x.enumId !== PartenaireCategorieEnum.fournisseurs);
          break;
        }
      }
      if (!this.initialized) {
        this.setDataSource();
        this.initialized = true;
      }

    });
  }

  get partenaireCategorieIntitule(): string {
    return partenaireCategoriesLibellesMap.get(this.partenaireCategorie);
  }

  setDataSource() {
    const estClient: boolean | null = this.partenaireCategorie == PartenaireCategorieEnum.clients ? true : null;
    const estFournisseur: boolean | null = this.partenaireCategorie == PartenaireCategorieEnum.fournisseurs ? true : null;
    const partenaireType: PartenaireType | null = this.partenaireCategorie == PartenaireCategorieEnum.internes ? PartenaireType.interne
      : this.partenaireCategorie == PartenaireCategorieEnum.externes ? PartenaireType.externe
        : null;
    this.dataSource = this.dxDataSourceService.getPartenaireCustomStore(estClient, estFournisseur, partenaireType, this.pattern);
  }

  clearDatagridFilters() {
    this.dataGrid?.instance.clearFilter();
  }

  async importDataFromCsv() {
    this.resetImportResultMsg();
    this.showLoader = true;
    try {
      const response: CsvImportResultDto = await lastValueFrom(this.partenairesLexiClient.importPartenairesFromCsv(true, this.fichierCsv[0]));

      // Erreur
      if (response.hasFailed) {
        this.importHasFailed = true;
        if (response.errorMessage != null) {
          this.importCsvResultMsg = response.errorMessage;
        }

        else {
          if (response.hasWrongHeadersCount) {
            this.importCsvResultMsg = "Le nombre de colonnes est incorrecte, il y a plus de colonnes que celles attendues.";
          }

          else {
            this.importCsvResultMsg = `Les colonnes suivantes sont invalides: ${response.invalidHeaders.join(', ')}.`;
          }

          if (response.expectedHeaders != null) {
            this.importCsvResultMsg2 = `Colonnes attendues: (${response.expectedHeaders.join(', ')})`;
          }
        }
      }

      else if (response.itemsOnError.length > 0) {
        let errorMessage = "";
        response.itemsOnError.forEach(x => errorMessage += `${x}\n`);
        this.importCsvResultMsg = `Certaines éléments n'ont pas pu être importées. Le détail a été copié dans le presse papier`;
        this.importCsvResultMsg2 = "Importation incomplète";
        navigator.clipboard.writeText(errorMessage).then().catch(e => console.error(e));
        this.setDataSource();
      }

      // Succès
      else {
        this.importHasFailed = false;
        this.setDataSource();
        this.importCsvResultMsg = `${response.nbItemsCreated} élément(s) ont été créé(s), ${response.nbItemsUpdated} élément(s) ont été mis à jour.\nL'importation s'est terminée.`;
      }

      this.fichierCsv = [];
    }
    finally {
      this.showLoader = false;
    }
  }

  cancelImportCsv() {
    this.showImportCsvPopup = false;
    this.resetImportResultMsg();
    this.fichierCsv = [];
  }

  resetImportResultMsg() {
    this.importCsvResultMsg = null;
    this.importCsvResultMsg2 = null;
  }

  onRowPrepared(e) {
    if (!e.data?.actif && e.rowType == "data") {
      e.rowElement.classList.add("row-bloque");
    }
  }

  onPartenaireCategoryChanged(e: { value: PartenaireCategorieEnum }) {
    this.partenaireCategorie = e.value;
    this.setDataSource();
  }

  async downloadTemplate() {
    try {
      this.recuperationTemplateEncours = true;
      const response = await lastValueFrom(this.partenaireService.getTemplateImportCsv('response'));
      this.downloadService.directDownloadFile(response, 'csv');
    }
    finally {
      this.recuperationTemplateEncours = false;
    }
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Partenaires');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Code
          if (gridCell.column.caption === 'Code' && gridCell.value == null) {
            excelCell.value = gridCell.data.codeBoClient ?? gridCell.data.codeBo;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-${this.partenaireCategorieIntitule}.xlsx`);
      });
    });
  }

  addPartenaire() {
    this.isAddPartenairePopupOpened = true;
  };
  isAddPartenairePopupOpened: boolean = false;
  onClickSaveNewContact() {
    this.isAddPartenairePopupOpened = false;
  }

  onClickSaveNewPartenaire = () => {
    const newPartenaire = this.partenaireNewForm.getNewPartenaireData();
    if(!newPartenaire) return;
    
    this.partenairesLexiClient.create(newPartenaire).subscribe(() => {
      notify({
        message: `Nouveau partenaire "${newPartenaire.codeBo
          } ${newPartenaire.intitule}" saved`,
        position: { at: 'bottom center', my: 'bottom center' }
      },
        'success');

    });
  }
}
