import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CaracteristiqueDto, CaracteristiqueValueDto, CaracteristiquesLexiClient, ObjectType, Permissions } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-gestion-caracteristiques',
  templateUrl: './gestion-caracteristiques.component.html',
  styleUrls: ['./gestion-caracteristiques.component.scss'],
})
export class GestionCaracteristiquesComponent implements OnInit {
  private _datagrid: DxDataGridComponent;
  get datagrid() { return this._datagrid; }
  @ViewChild(DxDataGridComponent) set datagrid(value: DxDataGridComponent) {
    this._datagrid = value;
    if (value && this.heightToDeduce != null) {
      value.instance.element().style.height = `calc(100vh - ${this.heightToDeduce}px)`;
    }
  }

  private _heightToDeduce: number;
  get heightToDeduce() { return this._heightToDeduce; }
  @Input() set heightToDeduce(value: number) {
    this._heightToDeduce = value;
    if (value && this.datagrid != null) {
      this.datagrid.instance.element().style.height = `calc(100vh - ${value}px)`;
    }
  }

  private _objectType: ObjectType;
  get objectType() { return this._objectType; }
  @Input() set objectType(value: ObjectType) {
    this._objectType = value;
    if (value && this.objectId != null) {
      this.setDataSource();
    }
  }

  private _objectId: number;
  get objectId() { return this._objectId; }
  @Input() set objectId(value: number) {
    this._objectId = value;
    if (value && this.objectType != null) {
      this.setDataSource();
    }
  }

  canModifierCaracteristiques: boolean = false;
  dataSource: CaracteristiqueValueDto[];
  caracteristiquesList: CaracteristiqueDto[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly caracteristiqueService: CaracteristiquesLexiClient
  ) { }

  async ngOnInit() {
    this.canModifierCaracteristiques = this.authService.securityUserisGrantedWith(Permissions.canModifierCaracteristiques);
    await this.setDataSource();
  }

  async setDataSource() {
    if(this.objectType) {
      this.caracteristiquesList = await lastValueFrom(this.caracteristiqueService.getCaracteristiquesByObjectType(this.objectType));
      if(this.objectId) {
        this.dataSource = await lastValueFrom(this.caracteristiqueService.getAll(this.objectType, this.objectId));
      }
    }
  }

  async onRowInserting(data: CaracteristiqueValueDto) {
    await lastValueFrom(this.caracteristiqueService.setValue(data.caracteristique.id, this.objectType, this.objectId, data.valeur));
    await this.setDataSource();
  }

  async onRowUpdating(e: { oldData: CaracteristiqueValueDto, newData: CaracteristiqueValueDto}) {
    for(const field of Object.keys(e.newData)) {
      e.oldData[field] = e.newData[field];
    }
    await lastValueFrom(this.caracteristiqueService.setValue(e.oldData.caracteristique.id, this.objectType, this.objectId, e.oldData.valeur));
    await this.setDataSource();
  }

  async onRowRemoving(data: CaracteristiqueValueDto) {
    await lastValueFrom(this.caracteristiqueService.deleteCaracteristiqueValue(data.id));
    await this.setDataSource();
  }

  calculateCellValue(rowData) {
    if (rowData?.caracteristique?.obligatoire == null) {
      return false;
    }
    return rowData?.caracteristique?.obligatoire
  }

  async onKeyDown(e) {
    if (this.canModifierCaracteristiques && e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }
}
