import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BonListService } from 'lexi-angular/src/app/services/bon.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';

@Component({
  selector: 'app-article-detail-bon-list',
  templateUrl: './article-detail-bon-list.component.html',
  styleUrls: ['./article-detail-bon-list.component.scss'],
})
export class ArticleDetailBonListComponent {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value) {
      this.loadComponentData();
    }
    this.setDatagridHeight();
    this.cd.detectChanges();
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }

  @Input() articleId: number;
  @Input() currentPartenaireId: number;
  dataSource: CustomStore;

  constructor(
    private readonly bonListService: BonListService,
    private readonly cd: ChangeDetectorRef
  ) { }

  async loadComponentData() {
    await this.setDataSource();
  }

  async setDataSource() {
    const additionalParams = new Map().set("articleId", this.articleId);
    this.dataSource = new CustomStoreService(this.bonListService).getCustomStore(additionalParams);
  }

  refreshDataGrid() {
    this.dataGrid?.instance.refresh();
  }

  clearDatagridFilters() {
    this.dataGrid.instance.clearFilter();
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }
}
