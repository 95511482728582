<div style="padding: 12px;">
  <h4>Création de société</h4>
  <dx-form id="form" [formData]="societeToCreate" *ngIf="!requeteServeurEnCours">
    <dxi-item itemType="group" caption="Société">
      <dxi-item dataField="codeBo" [label]="{ text: 'Code' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="intitule" [label]="{ text: 'Intitulé' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="{{'Site' | internationalisation}}">
      <dxi-item dataField="siteCodeBo" [label]="{ text: 'Code' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="siteIntitule" [label]="{ text: 'Intitulé' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dx-form>
  <dx-button  text="Créer" (onClick)='creer()' stylingMode="contained" type="default" [disabled]="requeteServeurEnCours"></dx-button>
</div>

<div *ngIf="requeteServeurEnCours" style="display: flex; justify-content: center; align-items: center;">
  <dx-load-indicator [visible]="requeteServeurEnCours" height="150" width="150"></dx-load-indicator>
</div>
