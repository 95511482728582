import { Component, OnInit } from '@angular/core';
import { ProfilPartenaireDto, PartenaireDto, ProfilPartenairesLexiClient, PartenaireType, Civilite } from '@lexi-clients/lexi';
import { forkJoin } from 'rxjs';
import { getSizeQualifier } from 'lexi-angular/src/app/shared/services/screen.service';

@Component({
  selector: 'app-partenaire-new-form',
  templateUrl: './partenaire-new-form.component.html',
})
export class PartenaireNewFormComponent implements OnInit {

  getSizeQualifier = getSizeQualifier;

  profilPartenaires: ProfilPartenaireDto[];
  partenaireType = PartenaireType;
  partenaireData: PartenaireDto = {} as PartenaireDto;

  // phone validation pattern
  phonePattern: any = /^[^a-zA-Z]*$/;
  phoneBrokePatternMessage: string = "Ce champ ne doit pas contenir de lettres";
  
  constructor(
    private readonly profilPartenairesLexiClient: ProfilPartenairesLexiClient) { }

  async ngOnInit() {
    await this.loadSelectBox();
  }

  async loadSelectBox() {
    forkJoin({
      profilsData: this.profilPartenairesLexiClient.getAll()
    }).subscribe({
      next: (data: any) => {
        this.profilPartenaires = data.profilsData;
      },
      error: (err: any) => {
      }
    });
  }

  clonePartenaire(data: PartenaireDto)
  {
    this.partenaireData = { ...data };
    this.partenaireData.id = 0;
    this.partenaireData.codeBo = null;
    this.partenaireData.intitule = null;
    this.partenaireData.adresseGeographique1 = null;
    this.partenaireData.adresseGeographique2 = null;
    this.partenaireData.adresseGeographique3 = null;
    this.partenaireData.raisonSociale = null;
    this.partenaireData.telecopie = null;
    this.partenaireData.telephoneFixe = null;
    this.partenaireData.adressePostale1 = null;
    this.partenaireData.adressePostale2 = null;
    this.partenaireData.adressePostale3 = null;
    this.partenaireData.email = null;
    this.partenaireData.representantLegal = null;
    this.partenaireData.telephonePortable = null;
    this.partenaireData.telephoneAutre = null;
    this.partenaireData.siteWeb = null;
    this.partenaireData.boitePostale = null;
    this.partenaireData.codePostal = null;
    this.partenaireData._synchronized = false;
    this.partenaireData.synchronisationInfo = null;
    this.partenaireData.nom = null;
    this.partenaireData.prenom = null;
    this.partenaireData.formeJuridiqueId = null;
    this.partenaireData.actif = true;
    this.partenaireData.codeBoClient = null;
    this.partenaireData.codeBoFournisseur = null;
    this.partenaireData.compteAuxiliaireClient = null;
    this.partenaireData.compteAuxiliaireFournisseur = null;
    this.partenaireData.dateNaissance = null;
    this.partenaireData.dateFinValiditeCarte = null;
    this.partenaireData.enseigne = null;
    this.partenaireData.fluxStatut = null;
    this.partenaireData.evenementId = null;
    this.partenaireData.identifiantCommercial = null;
    this.partenaireData.identifiantEntreprise = null;
    this.partenaireData.numeroTvaIntracommunautaire = null;
    this.partenaireData.secteurGeographiqueId = null;
    this.partenaireData.informationsBancaire = null;
    this.partenaireData.informationsLivraison = null;
    this.partenaireData.commune = null;
    this.partenaireData.partenaireFactureId = null;
    this.partenaireData.partenaireParentId = null;
  }

  getNewPartenaireData = ()=> ({ ...this.partenaireData });

  partenaireDisplayExpr(data: PartenaireDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }
}