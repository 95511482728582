import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FluxEtapeDto, LexiMouvementTypeDto, LieuStockageDto, LieuStockagesLexiClient, MouvementSens } from "@lexi-clients/lexi";
import { DxSelectBoxComponent } from "devextreme-angular";
import { BonListGlobaleDatagridComponent } from "../bon-list-globale-datagrid/bon-list-globale-datagrid.component";
import { BonListLieuStockageDatagridComponent } from "../bon-list-lieu-stockage-datagrid/bon-list-lieu-stockage-datagrid.component";
import CustomStore from "devextreme/data/custom_store";
import { AllBonListService } from "../../../../services/all-bons.service";
import { DxDataSourceService } from "../../../../shared/services/dx-data-source.service";

@Component({
  selector: "app-bon-list",
  templateUrl: "./all-bon-list.component.html",
  styleUrls: ["./all-bon-list.component.scss"],
})
export class AllBonListComponent implements OnInit {
  @ViewChild("lieuStockageSelectBox") lieuStockageSelectBox: DxSelectBoxComponent;
  @ViewChild("bonListGlobaleDatagridComponent") bonListGlobaleDatagridComponent: BonListGlobaleDatagridComponent;
  @ViewChild("bonListLieuStockageDatagridComponent") bonListLieuStockageDatagridComponent: BonListLieuStockageDatagridComponent;
  readonly LIEU_STOCKAGE_STORAGE_KEY = "bon_list_lieu_stockage";
  MouvementSens: MouvementSens;
  lieuStockages: LieuStockageDto[];
  selectedLieuStockage: LieuStockageDto;
  currentSiteId: number;
  fluxEtapeDataSource: FluxEtapeDto[];
  mouvementTypeDataSource: LexiMouvementTypeDto[] = [];
  isMultiSite : boolean = false;


  bonStore: CustomStore;


  ngOnInit() {
    this.setSelectedLieuStockageOnInit();

  }


  private setSelectedLieuStockageOnInit() {
    const storedString = localStorage.getItem(this.LIEU_STOCKAGE_STORAGE_KEY);
    if (storedString) {
      this.selectedLieuStockage = JSON.parse(storedString);
    }
  }

  private clearSelectedLieuStockage() {
    this.selectedLieuStockage = null;
    localStorage.removeItem(this.LIEU_STOCKAGE_STORAGE_KEY);
  }

  onValueChanged = (e: { value: number }) => {
    if (e.value) {
      this.selectedLieuStockage = this.lieuStockages.find(x => x.id == e.value);
      localStorage.setItem(this.LIEU_STOCKAGE_STORAGE_KEY, JSON.stringify(this.selectedLieuStockage));
    } else {
      this.clearSelectedLieuStockage();
    }
  };

  refreshDataSource() {
    this.selectedLieuStockage
      ? this.bonListLieuStockageDatagridComponent && this.bonListLieuStockageDatagridComponent.refreshDataGrid()
      : this.bonListGlobaleDatagridComponent && this.bonListGlobaleDatagridComponent.refreshDataGrid();
  }
}


