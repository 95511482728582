/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Civilite } from '../model/models';
import { CreatePartenaireRequestDto } from '../model/models';
import { CsvImportResultDto } from '../model/models';
import { GetPartenairesResponseDto } from '../model/models';
import { GetProfilPartenaireResponseDto } from '../model/models';
import { GroupingInfo } from '../model/models';
import { LoadResult } from '../model/models';
import { PartenaireDto } from '../model/models';
import { PartenaireResponse } from '../model/models';
import { PartenaireType } from '../model/models';
import { SortingInfo } from '../model/models';
import { SummaryInfo } from '../model/models';
import { UpdatePartenaireRequestDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PartenairesLexiClient {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param partenaireDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(partenaireDto: PartenaireDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public create(partenaireDto: PartenaireDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public create(partenaireDto: PartenaireDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public create(partenaireDto: PartenaireDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (partenaireDto === null || partenaireDto === undefined) {
            throw new Error('Required parameter partenaireDto was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/api/Partenaires`,
            partenaireDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param createPartenaireRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create2(createPartenaireRequestDto: CreatePartenaireRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public create2(createPartenaireRequestDto: CreatePartenaireRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public create2(createPartenaireRequestDto: CreatePartenaireRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public create2(createPartenaireRequestDto: CreatePartenaireRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (createPartenaireRequestDto === null || createPartenaireRequestDto === undefined) {
            throw new Error('Required parameter createPartenaireRequestDto was null or undefined when calling create2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/apipartenaire/partenaires`,
            createPartenaireRequestDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pageNumber Numéro de page, __facultatif__              Valeur par défaut : 1
     * @param pageSize Taille de la page., __facultatif__              Valeur par défaut : 200
     * @param id Id du partenaire, _faculatif_              Si on souhaite le renseigner, saisir _Id_ OU _Code (pas les 2 à la fois)
     * @param code Code du partenaire, _faculatif_              Si on souhaite le renseigner, saisir _Code OU _Id_ (pas les 2 à la fois)
     * @param intitule Motif de recherche pour l\&#39;intitule du partenaire
     * @param profilIntitule Motif de recherche pour l\&#39;intitule du profil partenaire
     * @param profilPartenaireId Id du profil partenaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetPartenairesResponseDto>;
    public get(pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetPartenairesResponseDto>>;
    public get(pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetPartenairesResponseDto>>;
    public get(pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'PageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'PageSize');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'Id');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'Code');
        }
        if (intitule !== undefined && intitule !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>intitule, 'Intitule');
        }
        if (profilIntitule !== undefined && profilIntitule !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>profilIntitule, 'ProfilIntitule');
        }
        if (profilPartenaireId !== undefined && profilPartenaireId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>profilPartenaireId, 'ProfilPartenaireId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GetPartenairesResponseDto>(`${this.configuration.basePath}/apipartenaire/partenaires`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllProfil(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GetProfilPartenaireResponseDto>>;
    public getAllProfil(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GetProfilPartenaireResponseDto>>>;
    public getAllProfil(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GetProfilPartenaireResponseDto>>>;
    public getAllProfil(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<GetProfilPartenaireResponseDto>>(`${this.configuration.basePath}/apipartenaire/profils`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PartenaireDto>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PartenaireDto>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PartenaireDto>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PartenaireDto>(`${this.configuration.basePath}/api/Partenaires/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById2(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PartenaireResponse>;
    public getById2(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PartenaireResponse>>;
    public getById2(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PartenaireResponse>>;
    public getById2(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PartenaireResponse>(`${this.configuration.basePath}/apipartenaire/partenaires/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPartenaires(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PartenaireDto>>;
    public getPartenaires(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PartenaireDto>>>;
    public getPartenaires(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PartenaireDto>>>;
    public getPartenaires(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PartenaireDto>>(`${this.configuration.basePath}/api/Partenaires`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateImportCsv(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getTemplateImportCsv(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getTemplateImportCsv(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getTemplateImportCsv(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/Partenaires/template-import-csv`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateIfExist 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importPartenairesFromCsv(updateIfExist?: boolean, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CsvImportResultDto>;
    public importPartenairesFromCsv(updateIfExist?: boolean, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CsvImportResultDto>>;
    public importPartenairesFromCsv(updateIfExist?: boolean, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CsvImportResultDto>>;
    public importPartenairesFromCsv(updateIfExist?: boolean, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (updateIfExist !== undefined && updateIfExist !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>updateIfExist, 'updateIfExist');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CsvImportResultDto>(`${this.configuration.basePath}/api/Partenaires/import-csv`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param etLogique Si true, les critères de recherche seront combinés via l\&#39;opérateur \&quot;et\&quot;. Si false, les critères de recherches seront combinés par l\&#39;operateur \&quot;ou\&quot;              False par defaut
     * @param pageNumber Numéro de page, __facultatif__              Valeur par défaut : 1
     * @param pageSize Taille de la page., __facultatif__              Valeur par défaut : 200
     * @param id Id du partenaire, _faculatif_              Si on souhaite le renseigner, saisir _Id_ OU _Code (pas les 2 à la fois)
     * @param code Code du partenaire, _faculatif_              Si on souhaite le renseigner, saisir _Code OU _Id_ (pas les 2 à la fois)
     * @param intitule Motif de recherche sur l\&#39;intitule
     * @param profilIntitule Motif de recherche sur l\&#39;intitule du profil partenaire
     * @param profilPartenaireId Id du profil partenaire
     * @param adresseGeoGraphique Motif de recherche sur l\&#39;adresse géographique
     * @param nom Motif de recherche sur le nom
     * @param prenom 
     * @param raisonSociale Motif de recherche sur la raison sociale de l\&#39;entité
     * @param enseigne Motif de recherche sur l\&#39;enseigne ou nom commercial de l\&#39;entité
     * @param email Motif de recherche sur l\&#39;adresse email de la personne ou de l\&#39;entité
     * @param isProfessionnel Si le partenaire est professionnel
     * @param lieuParDefautId Id du lieu auquel est rattaché le partenaire par défaut   __Note__ : L\&#39;id ou le code du lieu par défaut peut être fourni, pas les deux.
     * @param codeLieuParDefaut Code du lieu auquel est rattaché le partenaire par défaut   __Note__ : Le code ou l\&#39;id du lieu par défaut peut être fourni, pas les deux. Taille max : 36 caractères
     * @param identifiantEntreprise Motif de recherche sur l\&#39;identifiant de l\&#39;entreprise (N° Tahiti en Polynésie, SIREN en France)              Taille max : 20 caractères
     * @param identifiantCommercial N° d\&#39;inscription au registre du commerce si applicable              Taille max : 100 caractères
     * @param compteGeneralClient Compte Général client (comptabilité)              Taille max : 20 caractères
     * @param compteAuxiliaireClient Compte auxiliaire client (comptabilité)              Taille max : 20 caractères
     * @param compteGeneralFournisseur Compte général fournisseur (comptabilité)              Taille max : 20 caractères
     * @param compteAuxiliaireFournisseur Compte auxiliaire fournisseur (comptabilité)              Taille max : 20 caractères
     * @param dateFinValiditeCarte Date de fin de validité de la carte du partenaire              Taille max : 20 caractères
     * @param siteWeb Site internet
     * @param adresseGeographique Motif de recherche sur l\&#39;adresse géographique  Taille max : 80 caractères
     * @param adressePostale Première ligne d\&#39;adresse postale  Taille max : 80 caractères
     * @param boitePostale Boite postale  Taille max : 12 caractères
     * @param codePostal Code postal de la commune  Taille max : 12 caractères
     * @param commune Commune  Taille max : 120 caractères
     * @param paysId Id du pays
     * @param codePays Code iso2 du pays              Taille max : 2 caractères
     * @param secteurGeographiqueId Id du secteur géographique                           __Note__ : L\&#39;id ou le code du secteur géographique peut être fourni, pas les deux.              
     * @param codeSecteurGeographique Code du secteur géographique              __Note__ : Le code ou l\&#39;id du secteur géographique peut être fourni, pas les deux.              Taille max : 36 caractères
     * @param telephone Motif de recherche sur uméro de téléphone/fax  Taille max : 30 caractères
     * @param estClient Est un client
     * @param estFournisseur Est un Fournisseur
     * @param formeJuridiqueId Id de la forme juridique (partenaire particulier)              __Note__ : L\&#39;id ou le code de la forme juridique peut être fourni, pas les deux.
     * @param codeFormeJuridique Code de la forme juridique (partenaire particulier)    __Note__ : Le code ou l\&#39;id de la forme juridique peut être fourni, pas les deux.
     * @param civilite Civilité (partenaire particulier)
     * @param dateNaissance Date de naissance (partenaire particulier)
     * @param adhesionOffresCommerciales Adhère aux offres commerciales
     * @param representantLegal Nom du représentant légal  Taille max : 100 caractères
     * @param soumisTva Est soumis à la tva
     * @param controleEnCours Un contrôle financier est en cours
     * @param encoursNegatifAutorise Est autorisé à avoir un encours négatif  False par défaut
     * @param plafondEncours Montant du plafond de l\&#39;encours
     * @param enCours Montant de l\&#39;encours
     * @param facturationHT Est facturé Hors Taxes
     * @param partenaireFactureId Id du partenaire facturé si c\&#39;est un autre partenaire qui est facturé pour les opérations réalisées              __Note__ : L\&#39;id ou le code du partenaire facturé peut être fourni, pas les deux.
     * @param codePartenaireFacture Code du partenaire facturé si c\&#39;est un autre partenaire qui est facturé pour les opérations réalisées              __Note__ : Le code ou l\&#39;id du partenaire facturé peut être fourni, pas les deux.
     * @param partenaireParentId Id du partenaire parent              __Note__ : L\&#39;id ou le code du partenaire parent peut être fourni, pas les deux.
     * @param codePartenaireParent Code du partenaire parent              __Note__ : Le code ou l\&#39;id du partenaire parent peut être fourni, pas les deux.
     * @param notes Motif de recherche sur les notes relatives au partenaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recherche(etLogique?: boolean, pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, adresseGeoGraphique?: string, nom?: string, prenom?: string, raisonSociale?: string, enseigne?: string, email?: string, isProfessionnel?: boolean, lieuParDefautId?: number, codeLieuParDefaut?: string, identifiantEntreprise?: string, identifiantCommercial?: string, compteGeneralClient?: string, compteAuxiliaireClient?: string, compteGeneralFournisseur?: string, compteAuxiliaireFournisseur?: string, dateFinValiditeCarte?: string, siteWeb?: string, adresseGeographique?: string, adressePostale?: string, boitePostale?: string, codePostal?: string, commune?: string, paysId?: number, codePays?: string, secteurGeographiqueId?: number, codeSecteurGeographique?: string, telephone?: string, estClient?: boolean, estFournisseur?: boolean, formeJuridiqueId?: number, codeFormeJuridique?: string, civilite?: Civilite, dateNaissance?: string, adhesionOffresCommerciales?: boolean, representantLegal?: string, soumisTva?: boolean, controleEnCours?: boolean, encoursNegatifAutorise?: boolean, plafondEncours?: number, enCours?: number, facturationHT?: boolean, partenaireFactureId?: number, codePartenaireFacture?: string, partenaireParentId?: number, codePartenaireParent?: string, notes?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetPartenairesResponseDto>;
    public recherche(etLogique?: boolean, pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, adresseGeoGraphique?: string, nom?: string, prenom?: string, raisonSociale?: string, enseigne?: string, email?: string, isProfessionnel?: boolean, lieuParDefautId?: number, codeLieuParDefaut?: string, identifiantEntreprise?: string, identifiantCommercial?: string, compteGeneralClient?: string, compteAuxiliaireClient?: string, compteGeneralFournisseur?: string, compteAuxiliaireFournisseur?: string, dateFinValiditeCarte?: string, siteWeb?: string, adresseGeographique?: string, adressePostale?: string, boitePostale?: string, codePostal?: string, commune?: string, paysId?: number, codePays?: string, secteurGeographiqueId?: number, codeSecteurGeographique?: string, telephone?: string, estClient?: boolean, estFournisseur?: boolean, formeJuridiqueId?: number, codeFormeJuridique?: string, civilite?: Civilite, dateNaissance?: string, adhesionOffresCommerciales?: boolean, representantLegal?: string, soumisTva?: boolean, controleEnCours?: boolean, encoursNegatifAutorise?: boolean, plafondEncours?: number, enCours?: number, facturationHT?: boolean, partenaireFactureId?: number, codePartenaireFacture?: string, partenaireParentId?: number, codePartenaireParent?: string, notes?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetPartenairesResponseDto>>;
    public recherche(etLogique?: boolean, pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, adresseGeoGraphique?: string, nom?: string, prenom?: string, raisonSociale?: string, enseigne?: string, email?: string, isProfessionnel?: boolean, lieuParDefautId?: number, codeLieuParDefaut?: string, identifiantEntreprise?: string, identifiantCommercial?: string, compteGeneralClient?: string, compteAuxiliaireClient?: string, compteGeneralFournisseur?: string, compteAuxiliaireFournisseur?: string, dateFinValiditeCarte?: string, siteWeb?: string, adresseGeographique?: string, adressePostale?: string, boitePostale?: string, codePostal?: string, commune?: string, paysId?: number, codePays?: string, secteurGeographiqueId?: number, codeSecteurGeographique?: string, telephone?: string, estClient?: boolean, estFournisseur?: boolean, formeJuridiqueId?: number, codeFormeJuridique?: string, civilite?: Civilite, dateNaissance?: string, adhesionOffresCommerciales?: boolean, representantLegal?: string, soumisTva?: boolean, controleEnCours?: boolean, encoursNegatifAutorise?: boolean, plafondEncours?: number, enCours?: number, facturationHT?: boolean, partenaireFactureId?: number, codePartenaireFacture?: string, partenaireParentId?: number, codePartenaireParent?: string, notes?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetPartenairesResponseDto>>;
    public recherche(etLogique?: boolean, pageNumber?: number, pageSize?: number, id?: number, code?: string, intitule?: string, profilIntitule?: string, profilPartenaireId?: number, adresseGeoGraphique?: string, nom?: string, prenom?: string, raisonSociale?: string, enseigne?: string, email?: string, isProfessionnel?: boolean, lieuParDefautId?: number, codeLieuParDefaut?: string, identifiantEntreprise?: string, identifiantCommercial?: string, compteGeneralClient?: string, compteAuxiliaireClient?: string, compteGeneralFournisseur?: string, compteAuxiliaireFournisseur?: string, dateFinValiditeCarte?: string, siteWeb?: string, adresseGeographique?: string, adressePostale?: string, boitePostale?: string, codePostal?: string, commune?: string, paysId?: number, codePays?: string, secteurGeographiqueId?: number, codeSecteurGeographique?: string, telephone?: string, estClient?: boolean, estFournisseur?: boolean, formeJuridiqueId?: number, codeFormeJuridique?: string, civilite?: Civilite, dateNaissance?: string, adhesionOffresCommerciales?: boolean, representantLegal?: string, soumisTva?: boolean, controleEnCours?: boolean, encoursNegatifAutorise?: boolean, plafondEncours?: number, enCours?: number, facturationHT?: boolean, partenaireFactureId?: number, codePartenaireFacture?: string, partenaireParentId?: number, codePartenaireParent?: string, notes?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (etLogique !== undefined && etLogique !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>etLogique, 'EtLogique');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'PageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'PageSize');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'Id');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'Code');
        }
        if (intitule !== undefined && intitule !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>intitule, 'Intitule');
        }
        if (profilIntitule !== undefined && profilIntitule !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>profilIntitule, 'ProfilIntitule');
        }
        if (profilPartenaireId !== undefined && profilPartenaireId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>profilPartenaireId, 'ProfilPartenaireId');
        }
        if (adresseGeoGraphique !== undefined && adresseGeoGraphique !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>adresseGeoGraphique, 'AdresseGeoGraphique');
        }
        if (nom !== undefined && nom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nom, 'Nom');
        }
        if (prenom !== undefined && prenom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>prenom, 'Prenom');
        }
        if (raisonSociale !== undefined && raisonSociale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>raisonSociale, 'RaisonSociale');
        }
        if (enseigne !== undefined && enseigne !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>enseigne, 'Enseigne');
        }
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'Email');
        }
        if (isProfessionnel !== undefined && isProfessionnel !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isProfessionnel, 'IsProfessionnel');
        }
        if (lieuParDefautId !== undefined && lieuParDefautId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lieuParDefautId, 'LieuParDefautId');
        }
        if (codeLieuParDefaut !== undefined && codeLieuParDefaut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codeLieuParDefaut, 'CodeLieuParDefaut');
        }
        if (identifiantEntreprise !== undefined && identifiantEntreprise !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifiantEntreprise, 'IdentifiantEntreprise');
        }
        if (identifiantCommercial !== undefined && identifiantCommercial !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifiantCommercial, 'IdentifiantCommercial');
        }
        if (compteGeneralClient !== undefined && compteGeneralClient !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>compteGeneralClient, 'CompteGeneralClient');
        }
        if (compteAuxiliaireClient !== undefined && compteAuxiliaireClient !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>compteAuxiliaireClient, 'CompteAuxiliaireClient');
        }
        if (compteGeneralFournisseur !== undefined && compteGeneralFournisseur !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>compteGeneralFournisseur, 'CompteGeneralFournisseur');
        }
        if (compteAuxiliaireFournisseur !== undefined && compteAuxiliaireFournisseur !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>compteAuxiliaireFournisseur, 'CompteAuxiliaireFournisseur');
        }
        if (dateFinValiditeCarte !== undefined && dateFinValiditeCarte !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFinValiditeCarte, 'DateFinValiditeCarte');
        }
        if (siteWeb !== undefined && siteWeb !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siteWeb, 'SiteWeb');
        }
        if (adresseGeographique !== undefined && adresseGeographique !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>adresseGeographique, 'AdresseGeographique');
        }
        if (adressePostale !== undefined && adressePostale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>adressePostale, 'AdressePostale');
        }
        if (boitePostale !== undefined && boitePostale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>boitePostale, 'BoitePostale');
        }
        if (codePostal !== undefined && codePostal !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codePostal, 'CodePostal');
        }
        if (commune !== undefined && commune !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>commune, 'Commune');
        }
        if (paysId !== undefined && paysId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paysId, 'PaysId');
        }
        if (codePays !== undefined && codePays !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codePays, 'CodePays');
        }
        if (secteurGeographiqueId !== undefined && secteurGeographiqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>secteurGeographiqueId, 'SecteurGeographiqueId');
        }
        if (codeSecteurGeographique !== undefined && codeSecteurGeographique !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codeSecteurGeographique, 'CodeSecteurGeographique');
        }
        if (telephone !== undefined && telephone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>telephone, 'Telephone');
        }
        if (estClient !== undefined && estClient !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>estClient, 'EstClient');
        }
        if (estFournisseur !== undefined && estFournisseur !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>estFournisseur, 'EstFournisseur');
        }
        if (formeJuridiqueId !== undefined && formeJuridiqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>formeJuridiqueId, 'FormeJuridiqueId');
        }
        if (codeFormeJuridique !== undefined && codeFormeJuridique !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codeFormeJuridique, 'CodeFormeJuridique');
        }
        if (civilite !== undefined && civilite !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>civilite, 'Civilite');
        }
        if (dateNaissance !== undefined && dateNaissance !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateNaissance, 'DateNaissance');
        }
        if (adhesionOffresCommerciales !== undefined && adhesionOffresCommerciales !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>adhesionOffresCommerciales, 'AdhesionOffresCommerciales');
        }
        if (representantLegal !== undefined && representantLegal !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>representantLegal, 'RepresentantLegal');
        }
        if (soumisTva !== undefined && soumisTva !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>soumisTva, 'SoumisTva');
        }
        if (controleEnCours !== undefined && controleEnCours !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>controleEnCours, 'ControleEnCours');
        }
        if (encoursNegatifAutorise !== undefined && encoursNegatifAutorise !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>encoursNegatifAutorise, 'EncoursNegatifAutorise');
        }
        if (plafondEncours !== undefined && plafondEncours !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>plafondEncours, 'PlafondEncours');
        }
        if (enCours !== undefined && enCours !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>enCours, 'EnCours');
        }
        if (facturationHT !== undefined && facturationHT !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>facturationHT, 'FacturationHT');
        }
        if (partenaireFactureId !== undefined && partenaireFactureId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>partenaireFactureId, 'PartenaireFactureId');
        }
        if (codePartenaireFacture !== undefined && codePartenaireFacture !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codePartenaireFacture, 'CodePartenaireFacture');
        }
        if (partenaireParentId !== undefined && partenaireParentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>partenaireParentId, 'PartenaireParentId');
        }
        if (codePartenaireParent !== undefined && codePartenaireParent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>codePartenaireParent, 'CodePartenaireParent');
        }
        if (notes !== undefined && notes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>notes, 'Notes');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GetPartenairesResponseDto>(`${this.configuration.basePath}/apipartenaire/partenaires/recherche`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requireTotalCount A flag indicating whether the total number of data objects is required.
     * @param requireGroupCount A flag indicating whether the number of top-level groups is required.
     * @param isCountQuery A flag indicating whether the current query is made to get the total number of data objects.
     * @param isSummaryQuery 
     * @param skip The number of data objects to be skipped from the start of the resulting set.
     * @param take The number of data objects to be loaded.
     * @param sort A sort expression.
     * @param group A group expression.
     * @param filter A filter expression.
     * @param totalSummary A total summary expression.
     * @param groupSummary A group summary expression.
     * @param select A select expression.
     * @param preSelect An array of data fields that limits the Select expression. The applied select expression is the intersection of PreSelect and Select.
     * @param remoteSelect A flag that indicates whether the LINQ provider should execute the select expression. If set to false, the select operation is performed in memory.
     * @param remoteGrouping A flag that indicates whether the LINQ provider should execute grouping. If set to false, the entire dataset is loaded and grouped in memory.
     * @param expandLinqSumType 
     * @param primaryKey An array of primary keys.
     * @param defaultSort The data field to be used for sorting by default.
     * @param stringToLower A flag that indicates whether filter expressions should include a ToLower() call that makes string comparison case-insensitive. Defaults to true for LINQ to Objects, false for any other provider.
     * @param paginateViaPrimaryKey If this flag is enabled, keys and data are loaded via separate queries. This may result in a more efficient SQL execution plan.
     * @param sortByPrimaryKey 
     * @param allowAsyncOverSync 
     * @param estClient 
     * @param partenaireType 
     * @param estFournisseur 
     * @param avecPieceASolder 
     * @param pattern 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, estClient?: boolean, partenaireType?: PartenaireType, estFournisseur?: boolean, avecPieceASolder?: boolean, pattern?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LoadResult>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, estClient?: boolean, partenaireType?: PartenaireType, estFournisseur?: boolean, avecPieceASolder?: boolean, pattern?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LoadResult>>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, estClient?: boolean, partenaireType?: PartenaireType, estFournisseur?: boolean, avecPieceASolder?: boolean, pattern?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LoadResult>>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, estClient?: boolean, partenaireType?: PartenaireType, estFournisseur?: boolean, avecPieceASolder?: boolean, pattern?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (requireTotalCount !== undefined && requireTotalCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireTotalCount, 'RequireTotalCount');
        }
        if (requireGroupCount !== undefined && requireGroupCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireGroupCount, 'RequireGroupCount');
        }
        if (isCountQuery !== undefined && isCountQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isCountQuery, 'IsCountQuery');
        }
        if (isSummaryQuery !== undefined && isSummaryQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isSummaryQuery, 'IsSummaryQuery');
        }
        if (skip !== undefined && skip !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>skip, 'Skip');
        }
        if (take !== undefined && take !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>take, 'Take');
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Sort');
            })
        }
        if (group) {
            group.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Group');
            })
        }
        if (filter) {
            filter.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Filter');
            })
        }
        if (totalSummary) {
            totalSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'TotalSummary');
            })
        }
        if (groupSummary) {
            groupSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'GroupSummary');
            })
        }
        if (select) {
            select.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Select');
            })
        }
        if (preSelect) {
            preSelect.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PreSelect');
            })
        }
        if (remoteSelect !== undefined && remoteSelect !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteSelect, 'RemoteSelect');
        }
        if (remoteGrouping !== undefined && remoteGrouping !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteGrouping, 'RemoteGrouping');
        }
        if (expandLinqSumType !== undefined && expandLinqSumType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expandLinqSumType, 'ExpandLinqSumType');
        }
        if (primaryKey) {
            primaryKey.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PrimaryKey');
            })
        }
        if (defaultSort !== undefined && defaultSort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>defaultSort, 'DefaultSort');
        }
        if (stringToLower !== undefined && stringToLower !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stringToLower, 'StringToLower');
        }
        if (paginateViaPrimaryKey !== undefined && paginateViaPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paginateViaPrimaryKey, 'PaginateViaPrimaryKey');
        }
        if (sortByPrimaryKey !== undefined && sortByPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByPrimaryKey, 'SortByPrimaryKey');
        }
        if (allowAsyncOverSync !== undefined && allowAsyncOverSync !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAsyncOverSync, 'AllowAsyncOverSync');
        }
        if (estClient !== undefined && estClient !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>estClient, 'estClient');
        }
        if (partenaireType !== undefined && partenaireType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>partenaireType, 'partenaireType');
        }
        if (estFournisseur !== undefined && estFournisseur !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>estFournisseur, 'estFournisseur');
        }
        if (avecPieceASolder !== undefined && avecPieceASolder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>avecPieceASolder, 'avecPieceASolder');
        }
        if (pattern !== undefined && pattern !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pattern, 'pattern');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LoadResult>(`${this.configuration.basePath}/api/Partenaires/recherche-devexpress`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param partenaireDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(id: number, partenaireDto: PartenaireDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PartenaireDto>;
    public update(id: number, partenaireDto: PartenaireDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PartenaireDto>>;
    public update(id: number, partenaireDto: PartenaireDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PartenaireDto>>;
    public update(id: number, partenaireDto: PartenaireDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling update.');
        }
        if (partenaireDto === null || partenaireDto === undefined) {
            throw new Error('Required parameter partenaireDto was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<PartenaireDto>(`${this.configuration.basePath}/api/Partenaires/${encodeURIComponent(String(id))}`,
            partenaireDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param updatePartenaireRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update2(id: number, updatePartenaireRequestDto: UpdatePartenaireRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public update2(id: number, updatePartenaireRequestDto: UpdatePartenaireRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public update2(id: number, updatePartenaireRequestDto: UpdatePartenaireRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public update2(id: number, updatePartenaireRequestDto: UpdatePartenaireRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling update2.');
        }
        if (updatePartenaireRequestDto === null || updatePartenaireRequestDto === undefined) {
            throw new Error('Required parameter updatePartenaireRequestDto was null or undefined when calling update2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put(`${this.configuration.basePath}/apipartenaire/partenaires/${encodeURIComponent(String(id))}`,
            updatePartenaireRequestDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
