<div class="view-wrapper list-page">
  <div class="filter-container profile-card dx-datagrid-headers" *ngIf="showFilter">
    <dx-form [formData]="filtersFromForm" width="200" (onEditorEnterKey)="getBonsSelonFiltres()" >
      <dxi-item>
        <div style="display: flex;">
          <div class="filter-header">Filtres</div>
          <dx-button style="margin-left: 6px;" icon="deletetable" hint="Réinitialiser" (onClick)="resetFilters()"></dx-button>
          <dx-button style="margin-left: 6px;" icon="search" hint="Rechercher" (onClick)="getBonsSelonFiltres()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item itemType="group">
        <dxi-item class="field">
          <dx-button text="Ouvert" (onClick)="onStatutSelected(fluxStatutSimplifieEnum.ouvert)" [disabled]="currentFiltreStatut == fluxStatutSimplifieEnum.ouvert"></dx-button>
          <dx-button text="Fermé" (onClick)="onStatutSelected(fluxStatutSimplifieEnum.ferme)" [disabled]="currentFiltreStatut == fluxStatutSimplifieEnum.ferme"></dx-button>
          <dx-button text="Tous" (onClick)="onStatutSelected(fluxStatutSimplifieEnum.tous)" [disabled]="currentFiltreStatut == fluxStatutSimplifieEnum.tous"></dx-button>
        </dxi-item>

        <dxi-item [label]="{text: 'N°'}" dataField="id" [editorOptions]="{showClearButton: true}"></dxi-item>
        <dxi-item dataField="intitule" [editorOptions]="{ showClearButton: true}"></dxi-item>

        <dxi-item dataField="debutOperation" [label]="{text: 'À partir du'}" editorType="dxDateBox" [editorOptions]="{type: 'datetime', showClearButton: true}"></dxi-item>
        <dxi-item dataField="finOperation" [label]="{text: 'Jusqu\' au'}" type="datetime" editorType="dxDateBox" [editorOptions]="{type: 'datetime', value: now, showClearButton: true}"></dxi-item>
      </dxi-item>

    </dx-form>
    <dx-select-box label="Article" [width]="200" [dataSource]="articleDataSource" [(value)]="articleIdFiltre"
                   valueExpr="id" displayExpr="libelleLong" [searchEnabled]="true" showClearButton="true"
                   [searchExpr]="['libelleLong', 'codeBo']"
    ></dx-select-box>
  </div>

  <dx-data-grid
    #dataGrid
    height="100%"
    width="100%"
    class="grid theme-dependent"
    [dataSource]="bonStore"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [filterSyncEnabled]="true"
    (onExporting)="onExporting($event)"
  >
    <!-- Toolbar -->
    <dxo-toolbar class="dx-toolbar">
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button stylingMode="text" (onClick)="onToggleMenu()" icon="filter"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="before">
        <div *dxTemplate>
          <h2 style="margin: 0">Liste des bons</h2>
        </div>
      </dxi-item>
      <dxi-item location="before" *ngIf="isMultiSite">
        <div *dxTemplate>
          <div class="field" style="display: flex; align-items: center; gap: 0.5rem; padding-left: 1rem">
            <dx-switch
              [(value)]="inclurePartenaireInterne"
              [elementAttr]="{class: 'mr-12'}"
            ></dx-switch>
            <span>Avec partenaire interne</span>
          </div>
        </div>
      </dxi-item>
      <dxi-item location="before">
        <div *dxTemplate>
          <div class="field" style="display: flex; align-items: center; gap: 0.5rem; padding-left: 1rem">
            <dx-switch
              [(value)]="includeInfosCalculees"
              [elementAttr]="{class: 'mr-12'}"
              (onValueChanged)="storeIncludeInfosCalculeesValue()"
            ></dx-switch>
            <span>Avec détails</span>
          </div>
        </div>
      </dxi-item>
      <dxi-item>
        <dx-drop-down-button
          text="Créer un bon"
          icon="add"
          type="default"
          stylingMode="contained"
          [items]="mouvementTypeDataSource"
          displayExpr="intitule"
          keyExpr="id"
          style="margin-right: 12px;"
          [width]="220"
          (onItemClick)="onCreateNewBon($event)"
          [disabled]="!isCreerBonBtnEnabled"
        ></dx-drop-down-button>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <div [title]="messageCannotCreerBon">
            <dx-button icon="upload" (onClick)="showImportPopup = true;" [disabled]="!isCreerBonBtnEnabled"></dx-button>
          </div>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearDatagridFilters()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Rafraîchir" (onClick)="refreshDataGrid()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton" location="after"></dxi-item>
      <dxi-item name="columnChooserButton" location="after"></dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-state-storing [enabled]="true" storageKey="grille_bons_globale" appStorage></dxo-state-storing>
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-scrolling showScrollbar="always"></dxo-scrolling>
    <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
    <dxo-export [enabled]="true" fileName="liste-des-bons"></dxo-export>

    <dxi-column
      dataField="id"
      caption="N°"
      cellTemplate="idBonCellTemplate"
      alignment="left"
    >
      <div *dxTemplate="let data of 'idBonCellTemplate'">
        <a routerLink="/bon/{{data.value}}">
          {{ data.value }}
        </a>
      </div>
    </dxi-column>
    <dxi-column
      dataField="codeBo"
      caption="Code"
      cellTemplate="codeBoBonCellTemplate"
      alignment="left"
    >
      <div *dxTemplate="let data of 'codeBoBonCellTemplate'">
        <a routerLink="/bon/{{data.data.id}}">
          {{ data.value }}
        </a>
      </div>
    </dxi-column>
    <dxi-column dataField="intitule" caption="Intitule" [visible]="true" width="160"></dxi-column>
    <dxi-column dataField="fluxStatut" caption="Statut" width="110" [visible]="false">
      <dxo-lookup [dataSource]="fluxStatutDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="evenementTypeId" caption="Étape" width="95" cellTemplate="paireEtatCellTemplate" alignment="center">
      <dxo-lookup [dataSource]="evenementTypes" displayExpr="intitule" valueExpr="id"></dxo-lookup>
      <div *dxTemplate="let data of 'paireEtatCellTemplate'">
        <img [src]="getEtapeLogo(data.value)" width="24" height="24" [title]="data.data.evenementIntitule ?? 'Aucune'" />
      </div>
    </dxi-column>
    <dxi-column dataField="dateSouhaite" caption="Date souhaitée" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="true" width="110"></dxi-column>
    <dxi-column dataField="dateOperation" caption="Date d'opération" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false" sortOrder="desc" width="155" cellTemplate="dateOperationCellTemplate">
      <div *dxTemplate="let data of 'dateOperationCellTemplate'">
        <span title="Date souhaitée : {{data.data.dateSouhaite | date:'dd/MM/yyyy HH:mm'}}">{{ data.value | date:'dd/MM/yyyy HH:mm' }}</span>
      </div>
    </dxi-column>
    <dxi-column dataField="mouvementTypeIntitule" caption="Type"></dxi-column>
    <dxi-column dataField="version" caption="Version" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false"></dxi-column>
    <dxi-column dataField="datePremiereEtape" caption="Date de création" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false" width="115"
                [allowFiltering]="false"
                [allowHeaderFiltering]="false"
                [allowSearch]="false"
                [allowSorting]="false"></dxi-column>
    <dxi-column dataField="derniereEtapeIntitule" caption="Étape intitule" [visible]="false"></dxi-column>
    <dxi-column dataField="dateDerniereEtape" caption="Date dernière étape" dataType="date" format="dd/MM/yyyy HH:mm" width="150"></dxi-column>
    <dxi-column dataField="utilisateurDerniereEtapeIntitule" caption="Utilisateur dernière étape" width="167"></dxi-column>

    <!-- Marchandises (mouvementées/total) -->
    <dxi-column caption="Marchandises" cellTemplate="marchandisesTemplate" alignment="center" headerCellTemplate="marchandisesHeaderTemplate">
      <div *dxTemplate="let data of 'marchandisesHeaderTemplate'">
        <span style="margin-right: 2px;">Marchandises</span>
        <i id="marchandisesLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
           (mouseenter)="showMarchandisesLegende = true" (mouseleave)="showMarchandisesLegende = false"></i>
      </div>
      <div *dxTemplate="let cell of 'marchandisesTemplate'">
        <div class="quantites-marchandises">{{ cell.data.nombreMarchandisesEncours?.toLocaleString() ?? 0 }}</div>
        <div class="slash-quantites">/</div>
        <div class="quantites-marchandises">{{ cell.data.nombreMarchandisesMouvementees?.toLocaleString() ?? " " }}</div>
        <div class="slash-quantites">/</div>
        <div class="quantites-marchandises">{{ cell.data.nombreMarchandises?.toLocaleString() }}</div>
      </div>
    </dxi-column>

    <!-- Références (mouvementé/total) -->
    <dxi-column caption="Réf. mouvementées" cellTemplate="referencesTemplate" headerCellTemplate="referencesHeaderTemplate" alignment="center" width="120">
      <div *dxTemplate="let data of 'referencesHeaderTemplate'">
        <span style="margin-right: 2px;">Références</span>
        <i id="referencesLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
           (mouseenter)="showreferencesLegende = true" (mouseleave)="showreferencesLegende = false"></i>
      </div>
      <div *dxTemplate="let cell of 'referencesTemplate'">
        <div class="quantites-references">{{ cell.data.nombreReferencesMouvementees?.toLocaleString() }}</div>
        <div class="slash-quantites">/</div>
        <div class="quantites-references">{{ cell.data.nombreReferences?.toLocaleString() }}</div>
      </div>
    </dxi-column>

    <!-- Source -->
    <dxi-column caption="Source" cellTemplate="sourceTemplate">
      <div *dxTemplate="let cell of 'sourceTemplate'">
        <span *ngIf="isMultiSite">{{ cell.data.paireStockageIntitule }}</span>
        <span *ngIf="!isMultiSite">{{ currentPartenaireId == cell.data.partenaireSourceId ? cell.data.sourceStockageIntitule : cell.data.partenaireSourceIntitule }}</span>
      </div>
    </dxi-column>
    <dxi-column dataField="PartenaireSourceId" caption="Partenaire source id" [visible]="false"></dxi-column>

    <dxi-column
      dataField="sens"
      caption=""
      cellTemplate="sensCellTemplate"
      alignment="center"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSearch]="false"
      [allowSorting]="false"
      [showInColumnChooser]="false"
      [width]="56"
    >
      <div *dxTemplate="let data of 'sensCellTemplate'">
        <span *ngIf="data.value == MouvementSens.entree" class="dx-icon-arrowright arrow-entree" title="Entrée"></span>
        <span *ngIf="data.value == MouvementSens.sortie" class="dx-icon-arrowright arrow-sortie" title="Sortie"></span>
        <span *ngIf="data.value == MouvementSens.inventaire" class="dx-icon-tags" title="Inventaire"></span>
      </div>
    </dxi-column>

    Destination
    <dxi-column caption="Destination" cellTemplate="destinationTemplate">
      <div *dxTemplate="let cell of 'destinationTemplate'">
        <span *ngIf="isMultiSite">{{ cell.data.referenceStockageIntitule }}</span>
        <span *ngIf="!isMultiSite">{{ currentPartenaireId == cell.data.partenaireDestinationId ? cell.data.destinationStockageIntitule : cell.data.partenaireDestinationIntitule }}</span>
      </div>
    </dxi-column>
    <dxi-column dataField="partenaireDestinationId" caption="Partenaire destination id" [visible]="false"></dxi-column>

    <dxi-column dataField="nombreMarchandisesEncours" caption="Marchandises en cours" [visible]="false"></dxi-column>
    <dxi-column dataField="nombreMarchandisesMouvementees" caption="Marchandises mouvementées" [visible]="false"></dxi-column>
    <dxi-column dataField="nombreMarchandises" caption="Marchandises totales" [visible]="false"></dxi-column>
    <dxi-column dataField="nombreReferencesMouvementees" caption="Réf. mouvementées" [visible]="false"></dxi-column>
    <dxi-column dataField="nombreReferences" caption="Réf. totales" [visible]="false"></dxi-column>
    <dxi-column dataField="sourceStockageIntitule" caption="Stockage source" [visible]="false"></dxi-column>
    <dxi-column dataField="partenaireSourceIntitule" caption="Partenaire source" [visible]="false"></dxi-column>
    <dxi-column dataField="destinationStockageIntitule" caption="Stockage destination" [visible]="false"></dxi-column>
    <dxi-column dataField="partenaireDestinationIntitule" caption="Partenaire destination" [visible]="false"></dxi-column>
  </dx-data-grid>
</div>

<!-- Popover: Légende Marchandises -->
<dx-popover target="#marchandisesLegendeIcon" [width]="230" [(visible)]="showMarchandisesLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    En cours / Mouvementé / Total
  </div>
</dx-popover>

<!-- Popover: Légende References -->
<dx-popover target="#referencesLegendeIcon" [width]="160" [(visible)]="showreferencesLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Mouvementé / Total
  </div>
</dx-popover>


<dx-popup
  title="Importer un fichier pour générer des bons"
  [(visible)]="showImportPopup"
  (onHiding)="onCloseImportPopup()"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="450"
  [height]="370"
>
  <div style="display: flex; align-items: baseline;">
    Type de fichier :
    <dx-select-box
      #typeImportBonSelectBox
      [items]="typeFichierImportBon"
      [width]="220"
      [showClearButton]="true"
      displayExpr="label"
      valueExpr="id"
      [value]="selectedTypeFichierImportBon"
      [searchEnabled]="true"
      (onValueChanged)="onTypeFichierImportValueChanged($event)"
    ></dx-select-box>
  </div>

  <dx-file-uploader #fileUploader
                    [multiple]="false"
                    [(value)]="files"
                    name="files[]"
                    [allowedFileExtensions]="['.xlsx']"
                    uploadMode="useForm"
                    selectButtonText="Choisir un fichier Excel"
                    labelText="ou Glisser/Déposer le fichier"
                    readyToUploadMessage=""
                    class="uploader">
  </dx-file-uploader>

  <div style="display: flex; justify-content: center; margin-top: 12px;">
    <dx-button
      type="danger"
      text="Annuler"
      style="margin: 0 10px 0 20px;"
      (onClick)="onCloseImportPopup()"
    ></dx-button>
    <dx-drop-down-button
      text="Générer les bons"
      icon="upload"
      [items]="mouvementTypeDataSource"
      displayExpr="intitule"
      keyExpr="id"
      style="margin-right: 40px;"
      [width]="220"
      (onItemClick)="importBon($event)"
      [dropDownOptions]="dropDownOptionsCreerBon"
    ></dx-drop-down-button>
  </div>

  <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
    <dx-load-indicator [visible]="showLoader" height="50" width="50"></dx-load-indicator>
  </div>
</dx-popup>
